import { gql, useBackgroundQuery } from "@apollo/client";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { Suspense, memo, useCallback, useTransition } from "react";
import { useParams } from "react-router-dom";

import Content from "./Content";
import Fallback from "./Fallback";

const SUBMISSION = gql`
  query Submission($id: ID!) {
    node(id: $id) {
      id
      ... on Submission {
        title
        createdAt
        updatedAt
        address
        description
        status
        imageUrls
      }
    }
  }
`;

export default applyTo(() => {
  const { id } = useParams();
  const [isPending, startTransition] = useTransition();
  const [queryRef, { fetchMore, refetch }] = useBackgroundQuery(SUBMISSION, {
    variables: {
      id,
    },
    fetchPolicy: "network-only"
  });

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  return (
    <Suspense fallback={<Fallback />}>
      <Content
        queryRef={queryRef}
        fetchMore={handleFetchMore}
        refetch={handleRefetch}
        loading={isPending} />
    </Suspense>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName("ViewSubmission"), memo));
