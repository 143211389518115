import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";
import { applyTo, pipe, prop, propOr } from "ramda";
import { memo } from "react";

import { defaultProps, displayName, propTypes } from "lib/react";

export default applyTo(
  ({ options, className, label, rows, helperText, ...rest }) => {
    const [field, meta] = useField(rest);
    return (
      <FormControl
        className={className}
        fullWidth={propOr(true, "fullWidth", rest)}
        error={!!(prop("touched", meta) && prop("error", meta))}>
        <InputLabel>{label}</InputLabel>
        <Select
          {...rest}
          {...field}
          fullWidth={propOr(true, "fullWidth", rest)}
          label={label}
        >
          {options.map((option, idx) => (
            <MenuItem value={option.value} key={`select-field-${option.label}-idx`}>{option.label}</MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  },
  pipe(
    propTypes({
      className: PropTypes.string,
      label: PropTypes.string,
      rows: PropTypes.number,
    }),
    defaultProps({
      className: null,
      label: null,
      rows: null,
    }),
    displayName("SelectField"),
    memo
  )
);
