import { Auth } from "aws-amplify";
import { path } from "ramda";
import { createContext, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Function to check and set the initial authentication status
    const checkAuthStatus = async () => {

      try {
        const user = await Auth.currentAuthenticatedUser();
        ReactGA.set({
          email: user.attributes.email
        });
        setAuthenticated(true);
      } catch (error) {
        console.error(error);
        setAuthenticated(false);
      }
    };
    // Check authentication status on component mount
    checkAuthStatus();
  }, []);

  const login = async (username, password) => {
    return Auth.signIn(username, password)
      .then(async () => {
        // let cognito catch up
        await new Promise((resolve) => setTimeout(resolve, 1500));
      })
      .then(() => {
        return Auth.currentSession()
          .then(path(["accessToken", "jwtToken"]))
          .then((token) => window.localStorage.setItem("token", token))
          .then(() => setAuthenticated(true));
      });
  };

  const logout = async () => {
    return Auth.signOut()
      .then(() => window.localStorage.removeItem("token"))
      .then(() => setAuthenticated(false));
  };

  const signup = async (email, password) => {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
      autoSignIn: {
        enabled: true,
      },
    })
      .then(async () => {
        // TODO: there must be a better way to do this...
        // let cognito catch up
        await new Promise((resolve) => setTimeout(resolve, 3000));
      })
      .then(() => {
        return Auth.currentSession()
          .then(path(["accessToken", "jwtToken"]))
          .then((token) => window.localStorage.setItem("token", token))
          .then(() => setAuthenticated(true));
      });
  };

  const checkAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setAuthenticated(!!user);
      setUser(user);
      return true;
    } catch (error) {
      setAuthenticated(false);
      setUser(null);
      return false;
    }
  };

  const getCurrentUser = async () => {
    return Auth.currentAuthenticatedUser();
  };

  const getAccessToken = async () => {
    return Auth.currentSession().then(path(["accessToken", "jwtToken"]));
  };

  const resetPassword = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  };

  const forgotPassword = async (email) => {
    return Auth.forgotPassword(email);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authenticated,
        user,
        login,
        logout,
        signup,
        getCurrentUser,
        getAccessToken,
        checkAuth,
        resetPassword,
        forgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
