import {
  AppRegistration,
  Book,
  Login,
  Logout,
  Map,
  Movie,
  Person,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useAuth } from "components/AuthContext";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import UserAvatar from "components/UserAvatar";
import { defaultProps, displayName, propTypes } from "lib/react";


export default applyTo(
  ({ close }) => {
    const { isAuthenticated } = useAuth();

    const handleClose = useCallback(() => {
      close();
    }, [close]);

    return (
      <Stack justifyContent="space-between" sx={{ height: "100%" }}>
        <Box>
          <Stack direction="row" alignItems="center" spacing={2} p={2}>
            {isAuthenticated && <UserAvatar />}
            <Typography variant="h4">On Set!</Typography>
          </Stack>
          <List>
            <ListItem component={Link} to="/explore" onClick={handleClose}>
              <ListItemIcon>
                <Map />
              </ListItemIcon>
              <ListItemText primary="Explore" />
            </ListItem>
            <ListItem component={Link} to="/search" onClick={handleClose}>
              <ListItemIcon>
                <Search />
              </ListItemIcon>
              <ListItemText primary="Search" />
            </ListItem>
            {/* <ListItem component={Link} to="/discover" onClick={handleClose}>
              <ListItemIcon>
                <NearMe />
              </ListItemIcon>
              <ListItemText primary="Discover" />
            </ListItem> */}
            <ListItem component={Link} to="/blog" onClick={handleClose}>
              <ListItemIcon>
                <Book />
              </ListItemIcon>
              <ListItemText primary="Blog" />
            </ListItem>
            <ListItem component={Link} to="/locations" onClick={handleClose}>
              <ListItemIcon>
                <Movie />
              </ListItemIcon>
              <ListItemText primary="Nearby" />
            </ListItem>
            <ListItem component={Link} to="/profile" onClick={handleClose}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
            {!isAuthenticated && (
              <>
                <Divider />
                <ListItem component={Link} to="/signup" onClick={handleClose}>
                  <ListItemIcon>
                    <AppRegistration />
                  </ListItemIcon>
                  <ListItemText primary="Sign up" />
                </ListItem>
                <ListItem component={Link} to="/login" onClick={handleClose}>
                  <ListItemIcon>
                    <Login />
                  </ListItemIcon>
                  <ListItemText primary="Log in" />
                </ListItem>
              </>
            )}
          </List>
        </Box>
        <List>
          <Divider />
          <ListItem component={Link} to="/about" onClick={handleClose}>
            <ListItemText primary="About" />
          </ListItem>
          <ListItem component={Link} to="/contact" onClick={handleClose}>
            <ListItemText primary="Contact" />
          </ListItem>
          <Divider />
          <ListItem component={Link} to="/privacy-policy" onClick={handleClose}>
            <ListItemText primary="Privacy Policy" />
          </ListItem>
          <ListItem
            component={Link}
            to="/terms-and-conditions"
            onClick={handleClose}
          >
            <ListItemText primary="Terms & Conditions" />
          </ListItem>

          {isAuthenticated && (
            <>
              <Divider />
              <ListItem component={Link} to="/logout" onClick={handleClose}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </>
          )}
        </List>
      </Stack>
    );
  },
  pipe(
    propTypes({
      close: PropTypes.func,
    }),
    defaultProps({
      close: () => { },
    }),
    displayName("Drawer"),
    memo
  )
);
