import { Grid, Stack, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { SetLocationCardFallback } from "components/SetLocationCard";
import { displayName } from "lib/react";

export default applyTo(() => {
  return (
    <Stack gap={2}>
      <Typography
        variant="h4"
        sx={{ fontFamily: "PalmCanyonDrive" }}
        textAlign="center"
      >
        Check this out!
      </Typography>
      <Typography variant="h2" sx={{ fontWeight: 900 }} textAlign="center">
        Featured Locations
      </Typography>
      <Grid container spacing={2}>
        {[1, 2, 3].map((idx) => (
          <Grid item xs={12} md={4} key={`featured-${idx}`}>
            <SetLocationCardFallback />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}, pipe(displayName("FeaturedLocations"), memo));
