import { applyTo, pipe } from "ramda";
import { Suspense, memo } from "react";

import { displayName } from "lib/react";

import { gql, useBackgroundQuery } from "@apollo/client";

import Content from "./Content";
import Fallback from "./Fallback";

const FEATURED_SET_LOCATIONS_QUERY = gql`
  query FeaturedSetLocationsGroupQuery {
    featuredLocationGroups(first: 1) {
      edges {
        node {
          id
          title
          setLocations(first: 3) {
            edges {
              node {
                id
                slug
                title
                imageUrls
                show {
                  edges {
                    node {
                      id
                      title
                      year
                    }
                  }
                }
                favorite
              }
            }
          }
        }
      }
    }
  }
`;

export default applyTo(() => {
  const [queryRef] = useBackgroundQuery(FEATURED_SET_LOCATIONS_QUERY);
  return (
    <Suspense fallback={<Fallback />}>
      <Content queryRef={queryRef} />
    </Suspense>
  );
}, pipe(displayName("FeaturedLocations"), memo));
