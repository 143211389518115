import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { applyTo, drop, head, pipe, split, startsWith, when } from "ramda";
import { memo, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import RouteChange from "components/RouteChange";
import { defaultProps, displayName, propTypes } from "lib/react";

import { AppProvider } from "components/AppContext";
import { LocationProvider } from "components/LocationContext";
import ScrollToTop from "components/ScrollToTop";
import AppFooter from "./components/AppFooter";
import Footer from "./components/Footer";
import Header from "./components/Header";

export const getLeadingPath = pipe(
  when(startsWith("/"), drop(1)),
  split("/"),
  head
);

// mostly just the routes that hide the footer
const APP_PATHS = [
  "explore",
  "profile",
  "search",
  "location",
  "locations",
  "show",
  "login",
  "signup",
  "forgot-password",
  "reset-password",
  'discover',
];

export default applyTo(
  () => {
    const isMobile = useMediaQuery("(max-width: 600px)");
    const { pathname } = useLocation();

    const header = useMemo(() => {
      return <Box component={Header} sx={{ minHeight: 75 }} />;
    }, []);

    const hasAppFooter = useMemo(() => APP_PATHS.includes(getLeadingPath(pathname)), [pathname]);

    const footer = useMemo(() => {
      // desktop
      if (!isMobile) {
        // render desktop footer on non-app paths - else hide the footer
        return !APP_PATHS.includes(getLeadingPath(pathname)) ? (
          <Footer />
        ) : null;
      }
      // mobile
      return hasAppFooter ? (
        <AppFooter />
      ) : (
        <Footer />
      );
    }, [isMobile, pathname, hasAppFooter]);


    return (
      <AppProvider>
        <LocationProvider>
          <Box>
            <CssBaseline />
            <RouteChange />
            <ScrollToTop />
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "min-content 1fr min-content",
                minHeight: "100vh",
                maxWidth: "100vw",
              }}
            >
              {header}
              <Box display="flex" component="main" width="100vw" sx={{ paddingBottom: hasAppFooter ? 7 : 0 }}>
                <Outlet />
              </Box>
              {footer}
            </Box>
          </Box>
        </LocationProvider>
      </AppProvider>
    );
  },
  pipe(
    propTypes({
      children: PropTypes.node,
      className: PropTypes.string,
    }),
    defaultProps({
      children: null,
      className: null,
    }),
    displayName("Layout"),
    memo
  )
);
