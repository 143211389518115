import { Box, Stack } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { displayName } from "lib/react";

import instagram from "./instagram.svg";
import twitter from "./twitter.svg";
import youtube from "./youtube.svg";

export default applyTo(
  () => (
    <Stack direction="row" gap={1} alignItems="center">
      <Box
        component="a"
        href="https://www.instagram.com/onset_app/"
        target="_blank"
      >
        <Box component="img" src={instagram} sx={{ width: 25, height: 25 }} />
      </Box>
      <Box component="a" href="https://twitter.com/onset_app" target="_blank">
        <Box component="img" src={twitter} sx={{ width: 20, height: 20 }} />
      </Box>
      <Box
        component="a"
        href="https://www.youtube.com/channel/UCTkJX74IqxcrNRaADxFdmQg"
        target="_blank"
      >
        <Box component="img" src={youtube} sx={{ width: 25, height: 25 }} />
      </Box>
    </Stack>
  ),
  pipe(displayName("SocialLinks"), memo)
);
