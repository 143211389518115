import {
  AppBar,
  Box,
  Container,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SocialLinks from "components/SocialLinks";
import { applyTo, pipe } from "ramda";
import { memo } from "react";
import { Link } from "react-router-dom";

import config from "conf";
import { displayName } from "lib/react";

import appStoreBadge from "./apple_store.svg";
import playStoreBadge from "./play_store.png";

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <AppBar position="static" component="footer">
      <Container sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <List sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <ListItem>
              <Typography variant="h6" style={{ fontWeight: 900 }}>
                Links
              </Typography>
            </ListItem>
            <ListItem component={Link} to="/contact" sx={{ fontSize: 14 }}>
              Contact
            </ListItem>
            <ListItem component={Link} to="/about" sx={{ fontSize: 14 }}>
              About
            </ListItem>
            <ListItem component={Link} to="/blog" sx={{ fontSize: 14 }}>
              Blog
            </ListItem>
            {!window.IS_REACT_NATIVE && (
              <>
                <ListItem
                  component={Link}
                  to="/terms-and-conditions"
                  sx={{ fontSize: 14 }}
                >
                  Terms & Conditions
                </ListItem>
                <ListItem
                  component={Link}
                  to="/privacy-policy"
                  sx={{ fontSize: 14 }}
                >
                  Privacy Policy
                </ListItem>
              </>
            )}
          </List>
          {/* don't show the "download the app - from within the app" */}
          {!window.IS_REACT_NATIVE ? (
            <List sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <ListItem sx={{ justifyContent: "flex-end" }}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 900 }}
                  textAlign="right"
                  noWrap
                >
                  Download The App
                </Typography>
              </ListItem>
              <ListItem
                component="a"
                target="_blank"
                href="https://apps.apple.com/us/app/on-set/6452802625"
                sx={{ justifyContent: "flex-end" }}
              >
                <Box
                  component="img"
                  src={appStoreBadge}
                  alt="apple app store"
                  width={150}
                />
              </ListItem>
              <ListItem
                component="a"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.getonsetapp.app"
                sx={{ justifyContent: "flex-end" }}
              >
                <Box
                  component="img"
                  src={playStoreBadge}
                  alt="play store"
                  width={150}
                />
              </ListItem>
            </List>
          ) : (
            <List
              sx={{ paddingLeft: 0, paddingRight: 0, alignSelf: "flex-end" }}
            >
              <ListItem
                component={Link}
                to="/terms-and-conditions"
                sx={{ fontSize: 14 }}
              >
                Terms & Conditions
              </ListItem>
              <ListItem
                component={Link}
                to="/privacy-policy"
                sx={{ fontSize: 14 }}
              >
                Privacy Policy
              </ListItem>
            </List>
          )}
        </Stack>
        <Divider sx={{ marginRight: 2, marginLeft: 2 }} />
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography component="span">
            Copyright {new Date().getFullYear()} © On Set! LLC
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography>
              v{config.VERSION} ({config.env})
            </Typography>
            {!isMobile && <SocialLinks />}
          </Stack>
          {isMobile && <SocialLinks />}
        </Stack>
      </Container>
    </AppBar>
  );
}, pipe(displayName("Footer"), memo));
