import { Marker, useGoogleMap } from "@react-google-maps/api";
import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, pipe, prop } from "ramda";
import { memo, useCallback } from "react";

import marker from "./marker.png";

// Docs:
// https://react-google-maps-api-docs.netlify.app/
export default applyTo(
  ({ geoLocation, onClick, clusterer }) => {
    const map = useGoogleMap();

    const handleMarkerClick = useCallback((event) => {
      event?.domEvent?.stopPropagation();
      map.panTo(prop("coordinate", geoLocation));
      onClick(prop("id", geoLocation));
    }, [map, onClick, geoLocation]);

    return (
      <Marker
        clusterer={clusterer}
        onClick={handleMarkerClick}
        position={prop("coordinate", geoLocation)}
        icon={marker}
      />
    );
  },
  pipe(
    propTypes({
      geoLocation: PropTypes.shape({
        slug: PropTypes.string,
        coordinate: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      }),
      onClick: PropTypes.func,
    }),
    defaultProps({
      coordinate: {
        // NYC
        lat: 40.7128,
        lng: -74.006,
      },
      onClick: () => { },
    }),
    displayName("MapMarker"),
    memo
  )
);
