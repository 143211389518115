import { Box, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import SEO from "components/SEO";
import { displayName } from "lib/react";

export default applyTo(() => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <SEO title="Not found" />
      <Typography variant="h2">404. Not Found.</Typography>
    </Box>
  );
}, pipe(displayName("NotFound"), memo));
