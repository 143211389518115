import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, isEmpty, join, pipe } from "ramda";
import { memo } from "react";
import Helmet from "react-helmet";

export default applyTo(
  ({ title, description, keywords }) => {
    if (title) {
      title = `On Set! | ${title}`;
    }
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        {description && <meta name="description" content={description} />}
        {!isEmpty(keywords) && (
          <meta name="keywords" content={join(", ", keywords)} />
        )}
      </Helmet>
    );
  },
  pipe(
    propTypes({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      keywords: PropTypes.arrayOf(PropTypes.string),
    }),
    defaultProps({
      title: "On Set!",
      description: null,
      keywords: [],
    }),
    displayName("SEO"),
    memo
  )
);
