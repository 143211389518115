import { gql, useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, InputAdornment, Stack } from '@mui/material';
import conf from 'conf';
import { useField } from 'formik';
import { uploadFile } from 'lib/file';
import { defaultProps, displayName, propTypes } from 'lib/react';
import { MuiFileInput } from 'mui-file-input';
import PropTypes from 'prop-types';
import { always, applyTo, map, path, pathOr, pipe } from 'ramda';
import { memo, useCallback, useState } from 'react';


const GET_SIGNED_URL = gql`
    query GetSignedUrl($filename: String!, $contentType: String!) {
        getSignedUrl(filename: $filename, contentType: $contentType)
    }
`;

// https://github.com/on-set/onset-website/blob/897d3e5114aa389d70127ce2d89e7f707541f65c/src/components/forms/SubmissionForm/index.js#L57
// https://viclafouch.github.io/mui-file-input/docs/api-reference/
export default applyTo(({
    className,
    label,
    ...rest
}) => {
    const [loading, setLoading] = useState(false);
    const [getSignedUrl] = useLazyQuery(GET_SIGNED_URL);
    const [field, _, { setValue }] = useField(rest); // eslint-disable-line

    const handleChange = useCallback(async (files) => {
        setLoading(true);
        // TODO: validate file types to be only images
        const imageUrls = await Promise.all(map(async (file) => {
            const filename = file.name;
            // get signed url
            return getSignedUrl({
                variables: {
                    filename,
                    contentType: file.type,
                },
            }).then(path(['data', 'getSignedUrl']))
                // uplaod file
                .then(signedUrl => uploadFile(signedUrl, file))
                // use url as value
                .then(always(`https://s3.amazonaws.com/onset-${conf.env === 'production' ? 'main' : 'dev'}-media/${filename}`))
        }, files));
        setValue([...pathOr([], ['value'], field), ...imageUrls]);
        setLoading(false)
    }, [getSignedUrl, setValue, field, setLoading]);

    return (
        <Stack gap={2}>

            {/* preview */}
            <Stack gap={2} direction="row" maxWidth='100%'>
                {pathOr([], ['value'], field).map((imageUrl) => (
                    <Box component="img" src={imageUrl} key={imageUrl} sx={{ width: 300, height: 200, objectFit: 'cover' }} />
                ))}
            </Stack>
            <MuiFileInput
                {...field}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {loading ? <CircularProgress size={20} /> : null}
                        </InputAdornment>
                    ),
                }}
                multiple={true}
                label={label}
                value={null}
                onChange={handleChange}
                fullWidth={true} />
        </Stack >
    )
}, pipe(
    propTypes({
        className: PropTypes.string,
        label: PropTypes.string,
    }),
    defaultProps({
        className: null,
        label: null,
    }),
    displayName('FileField'),
    memo,
));