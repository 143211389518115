import { Container, Grid, Stack } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { SetLocationCardFallback } from "components/SetLocationCard";
import { displayName } from "lib/react";

export default applyTo(() => {
  return (
    <Container>
      <Stack gap={4}>
        <Grid container spacing={2}>
          {[1, 2, 3].map((idx) => (
            <Grid item xs={12} sm={6} md={4} key={`blog-fallback-${idx}`}>
              <SetLocationCardFallback />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container >
  );
}, pipe(displayName("BlogFallback"), memo));
