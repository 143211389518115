import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo, useEffect, useMemo } from "react";

import { useReadQuery } from "@apollo/client";
import { useAppContext } from "components/AppContext";
import ContentfulRichText from "components/ContentfulRichText";
import SEO from "components/SEO";
import { displayName } from "lib/react";
import { path } from "ramda";

export default applyTo(({ queryRef }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { data } = useReadQuery(queryRef);
  const { setTitle, setBackPath } = useAppContext();

  const blog = useMemo(() => path(["blog"], data), [data]);
  const title = useMemo(() => path(["title"], blog), [blog]);
  const content = useMemo(() => path(["content"], blog), [blog]);

  useEffect(() => {
    setTitle(title);
    setBackPath('/blog')
  }, [title, setTitle, setBackPath]);

  return (
    <Container>
      <SEO title={title} />
      <Stack gap={2} sx={{ marginBottom: 2 }}>
        {!isMobile && <Typography variant='h5'>{path(['title'], blog)}</Typography>}
        <ContentfulRichText content={content} />
      </Stack>
    </Container >
  );
}, pipe(displayName("BlogContent"), memo));
