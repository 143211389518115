import { Skeleton, Stack } from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { memo } from "react";


export default applyTo(() => {
  return (
    <Stack gap={2} paddingTop={2} flexGrow={1}>
      {[1, 2, 3, 4, 5].map((idx) => (
        <Skeleton height={50} key={`submissions-fallback-${idx}`} />
      ))}
    </Stack>
  );
}, pipe(propTypes({}), defaultProps({}), displayName("SubmissionsFallback"), memo));
