import { gql, useBackgroundQuery } from "@apollo/client";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { Suspense, memo, useCallback, useTransition } from "react";
import { useParams } from "react-router-dom";

import Content from "./Content";
import Fallback from "./Fallback";

const LIST = gql`
query List($id: ID!, $first: Int, $after: String) {
  node(id: $id) {
    id
    ... on List {
      title
      items(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            __typename
            id
            ... on SetLocation {
              title
              slug
              id
              title
              slug
              imageUrls
              show {
                edges {
                  node {
                    id
                    title
                    year
                  }
                }
              }
              favorite
            }
          }
        }
      }
    }
  }
}
`;

export default applyTo(() => {
  const { id } = useParams();
  const [isPending, startTransition] = useTransition();
  const [queryRef, { fetchMore, refetch }] = useBackgroundQuery(LIST, {
    variables: {
      id,
      first: 6,
      after: null,
    },
    fetchPolicy: "network-only"
  });

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  return (
    <Suspense fallback={<Fallback />}>
      <Content
        queryRef={queryRef}
        fetchMore={handleFetchMore}
        refetch={handleRefetch}
        loading={isPending} />
    </Suspense>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName("ViewList"), memo));
