import { Button, Stack, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo, useCallback } from "react";

import { displayName } from "lib/react";
import { useNavigate } from "react-router-dom";

export default applyTo(() => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Stack
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Stack gap={0.5}>
        <Typography variant="h4" noWrap textAlign="center">
          Oops! This is embarassing...
        </Typography>
        <Typography variant="body1" noWrap textAlign="center">
          We ran into an error.
        </Typography>
        <Button onClick={handleClick}>Go Back</Button>
      </Stack>
    </Stack>
  );
}, pipe(displayName("ErrorElement"), memo));
