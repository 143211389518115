import { MapOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";
import { Link } from "react-router-dom";

import { displayName } from "lib/react";

import SEO from "components/SEO";
import SocialLinks from "components/SocialLinks";
import Youtube from "components/Youtube";
import iPhone from "./iPhone.png";

import FeaturedLocations from "./components/FeaturedLocations";
import JoinTheCommunity from "./components/JoinTheCommunity";

export default applyTo(() => {
  const hidePhonePhoto = useMediaQuery("(min-width: 968px)");

  return (
    <Container>
      <SEO title="Home" />
      <Stack gap={10} marginBottom={10}>
        <Stack direction="row">
          <Stack gap={2} flexGrow={1}>
            <Typography variant="h1" sx={{ fontWeight: 900 }}>
              Cinema can be experienced on more than just a screen.
            </Typography>
            <Typography
              variant="h6"
              style={{ fontWeight: "300", maxWidth: 400 }}
            >
              Find, visit, and explore the locations from all of your favorite
              movies and T.V. shows.
            </Typography>
            <Button
              component={Link}
              to="/explore"
              startIcon={<MapOutlined />}
              sx={{ width: 250 }}
            >
              Explore Nearby
            </Button>
            <SocialLinks />
          </Stack>
          {hidePhonePhoto && <Box component="img" src={iPhone} alt="onset" sx={{ maxWidth: 550, marginRight: -9, marginTop: -5 }} />}
        </Stack >
        <Stack gap={2}>
          <Typography
            variant="h4"
            sx={{ fontFamily: "PalmCanyonDrive" }}
            textAlign="center"
          >
            Action!
          </Typography>
          <Typography variant="h2" sx={{ fontWeight: 900 }} textAlign="center">
            How it works
          </Typography>
          {/* TODO: what should go here? maybe a video? */}
          {/*
                        1. Browse locations
                        2. plan your trip
                        3. visit the location and take photos
                        4. share your trip!
                    */}
          <Box display="flex" justifyContent="center">
            <Youtube url="https://www.youtube.com/embed/YGJmymd0poA" />
          </Box>
        </Stack>
        <FeaturedLocations />
        <JoinTheCommunity />
      </Stack >
    </Container >
  );
}, pipe(displayName("Home"), memo));
