import { Box, Card, CardContent, CardMedia, Stack, Typography, useMediaQuery } from "@mui/material";
import { applyTo, head, path, pathOr, pipe } from "ramda";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import { displayName } from "lib/react";

const toSetLocations = pipe(
  pathOr([], ["setLocations", "edges"]),
  // just get the first one for now
  head,
  path(["node"]),
  (setLocation) => {
    const title = path(["show", "edges", 0, "node", "title"], setLocation);
    const year = path(["show", "edges", 0, "node", "year"], setLocation);
    const subtitle = year ? `${title} (${year})` : title;
    return {
      title: path(["title"], setLocation),
      subtitle,
      slug: path(["slug"], setLocation),
      imageUrl: path(["imageUrls", 0], setLocation),
    };
  }
);

export default applyTo(({ geoLocation }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const setLocation = useMemo(() => {
    return applyTo(geoLocation, toSetLocations);
  }, [geoLocation]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 10,
        right: 10,
        left: isMobile ? 10 : 'initial',
        zIndex: 100,
        width: isMobile ? 'auto' : 350,
      }}
    >
      <Card
        component={Link}
        to={`/location/${path(["slug"], setLocation)}`}
        sx={{ textDecoration: "none", flexDirection: isMobile ? 'row' : 'column', display: 'flex', position: 'relative' }}
      >
        <CardMedia component="img" src={path(["imageUrl"], setLocation)} sx={{ flex: 1, width: isMobile ? 125 : 'auto', height: isMobile ? 'auto' : 200, minHeight: 125, objectFit: 'cover' }} />
        <CardContent variant="condensed" sx={{ padding: 2, background: '#ffffff', flex: 2 }}>
          <Stack alignItems="center" justifyContent="center" height='100%'>
            <Typography variant="h5" textAlign='center'>
              {path(["title"], setLocation)}
            </Typography>
            <Typography variant="body1" noWrap>
              {path(["subtitle"], setLocation)}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}, pipe(displayName("GeoLocationCard"), memo));
