import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import { useList } from "lib/useList";
import { applyTo, pipe } from "ramda";
import { memo, useCallback } from "react";

export default applyTo(({ listId, nodeId, title, onClick, status: initStatus }) => {
    const {
        toggle,
        status,
    } = useList({
        nodeId,
        type: "CUSTOM",
        listId,
        // TODO: fetch if the node is in the given list from somewhere - ideally not 1 query per node
        status: initStatus,
    });

    const handleClick = useCallback(async () => {
        await toggle();
        onClick();
    }, [toggle, onClick]);

    return (
        <MenuItem onClick={handleClick}>
            <ListItemText>{title}</ListItemText>
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                {!status ? <CheckBox /> : <CheckBoxOutlineBlank />}
            </ListItemIcon>
        </MenuItem>
    );
}, pipe(propTypes({}), defaultProps({}), displayName("List"), memo));
