import { gql, useBackgroundQuery } from "@apollo/client";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { Suspense, memo, useCallback, useTransition } from "react";

import Content from "./Content";
import Fallback from "./Fallback";

const VISITED = gql`
  query Visited($first: Int, $after: String) {
    whoami {
      id
      visited: lists(type: VISITED) {
        # lists returns a array of lists even though we just need 1 (visited)
        edges {
          node {
            id
            title
            items(first: $first, after: $after) {     
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }         
              edges {
                cursor
                node {
                  id
                  ... on SetLocation {
                    title
                    slug
                    id
                    title
                    slug
                    imageUrls
                    show {
                      edges {
                        node {
                          id
                          title
                          year
                        }
                      }
                    }
                    favorite
                    visited
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default applyTo(() => {
  const [isPending, startTransition] = useTransition();
  const [queryRef, { fetchMore, refetch }] = useBackgroundQuery(VISITED, {
    variables: {
      first: 6,
      after: null,
    },
    fetchPolicy: "network-only"
  });

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  return (
    <Suspense fallback={<Fallback />}>
      <Content
        queryRef={queryRef}
        fetchMore={handleFetchMore}
        refetch={handleRefetch}
        loading={isPending} />
    </Suspense>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName("Visited"), memo));
