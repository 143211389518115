import { ArrowForward } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { displayName } from "lib/react";
import { Link } from "react-router-dom";

export default applyTo(
  () => (
    <Stack gap={2}>
      <Typography
        variant="h4"
        sx={{ fontFamily: "PalmCanyonDrive" }}
        textAlign="center"
      >
        Find your people
      </Typography>
      <Typography variant="h2" sx={{ fontWeight: 900 }} textAlign="center">
        Join the community
      </Typography>
      <Button
        component={Link}
        to="/signup"
        sx={{ width: 250, alignSelf: "center" }}
        endIcon={<ArrowForward />}
      >
        Get Started
      </Button>
    </Stack>
  ),
  pipe(displayName("JoinTheCommunity"), memo)
);
