import { Grid, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { displayName } from "lib/react";
import TeamMember from "../TeamMember";

import Blank from "./blank.png";
import Jesse from "./jesse-reichenstein.png";
import JoeB from "./joe-brunett.png";
import JoeC from "./joe-cuffney.png";

const TEAM_MEMBERS = [
  {
    name: "Joe Brunett (Co-founder)",
    title: "CEO & Head of Horror",
    subtitle: "Favorite Movie: Halloween (1978)",
    src: JoeB,
  },
  {
    name: "Joe Cuffney (Co-founder)",
    title: "CTO & Man of Mystery",
    subtitle: "Favorite Movie: Hot Fuzz (2007)",
    src: JoeC,
  },
  {
    name: "Jesse Reichenstein",
    title: "Designer & Director of Comedy",
    subtitle: "Favorite Movie: Bowfinger (1999)",
    src: Jesse,
  },
  {
    name: "You?",
    title: "Film Fanatic",
    subtitle:
      "We're always looking to connect with fellow film fans, feel free to reach out to us!",
    src: Blank,
  },
];

export default applyTo(() => {
  return (
    <>
      <Typography variant="h2">Our Crew</Typography>
      <Grid container spacing={3}>
        {TEAM_MEMBERS.map((props) => (
          <Grid item xs={12} md={6} key={props.name}>
            <TeamMember {...props} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}, pipe(displayName("TeamMemberGrid"), memo));
