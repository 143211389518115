import { FormControl, TextField } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";
import { applyTo, pipe, prop, propOr } from "ramda";
import { memo } from "react";

import { defaultProps, displayName, propTypes } from "lib/react";

export default applyTo(
  ({ className, label, rows, helperText, ...rest }) => {
    const [field, meta] = useField(rest);
    return (
      <FormControl
        fullWidth={propOr(true, "fullWidth", rest)}
        className={className}
      >
        <TextField
          fullWidth={true}
          {...field}
          {...rest}
          label={label}
          multiline={rows > 0}
          rows={rows}
          error={!!(prop("touched", meta) && prop("error", meta))}
          helperText={propOr(helperText, "error", meta)}
        />
      </FormControl>
    );
  },
  pipe(
    propTypes({
      className: PropTypes.string,
      label: PropTypes.string,
      rows: PropTypes.number,
    }),
    defaultProps({
      className: null,
      label: null,
      rows: null,
    }),
    displayName("TextField"),
    memo
  )
);
