import {
  applyTo,
  pipe
} from "ramda";
import { Suspense, memo, useCallback, useState, useTransition } from "react";

import { gql, useBackgroundQuery } from "@apollo/client";
import { CircularProgress, Container, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import SEO from "components/SEO";
import { displayName } from "lib/react";
import useDebouncedCallback from "lib/useDebouncedCallback";
import ReactGA from "react-ga4";

import { useSearchParams } from "react-router-dom";
import Content from "./Content";
import Fallback from "./Fallback";

const PAGE_SIZE = 6;

export const SEARCH_QUERY = gql`
  query SearchLocations($first: Int, $after: String, $input: SearchInput!) {
    search(first: $first, after: $after, input: $input) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          __typename
          ... on Show {
            id
            title
            slug
            posterUrl
          }
        }
      }
    }
  }
`;

export default applyTo(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [contentType] = useState("SHOW");
  const [isPending, startTransition] = useTransition();
  const [queryRef, { refetch, fetchMore }] = useBackgroundQuery(SEARCH_QUERY, {
    variables: {
      first: PAGE_SIZE,
      input: { type: "TEXT", textSearch: { query: "Halloween", contentType: "SHOW" } },
    },
  });

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  const executeSearchQuery = useDebouncedCallback((query) => {
    setSearchParams({ q: query })
    startTransition(() => {
      refetch({
        first: PAGE_SIZE,
        input: { type: "TEXT", textSearch: { query, contentType } },
      });
    });

    ReactGA.event({
      // top level action
      category: "search",
      // type of user interaction i.e "click", "view", etc.
      action: "query",
      // provides additional context
      label: query,
    });
  }, 500);

  const handleChangeQuery = useCallback(
    (event) => {
      setQuery(event.target.value);
      executeSearchQuery(event.target.value);
    },
    [setQuery, executeSearchQuery]
  );

  return (
    <Container sx={{ marginTop: 2, display: "flex" }}>
      <SEO title="Search" />
      <Stack flexGrow={1} gap={2}>
        <Stack gap={5}>
          <Typography variant="h2" align="center">
            Search For Films
          </Typography>
          <Stack direction={{ xs: "column", md: "row" }} gap={2}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              onChange={handleChangeQuery}
              value={query}
              sx={{ width: "100%", maxWidth: 500, margin: "auto" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isPending ? <CircularProgress size={20} /> : null}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Suspense fallback={<Fallback />}>
            <Content
              query={query}
              contentType={contentType}
              queryRef={queryRef}
              refetch={handleRefetch}
              fetchMore={handleFetchMore}
              isPending={isPending} />
          </Suspense>
        </Stack>
      </Stack>
    </Container>
  );
}, pipe(displayName("Search"), memo));
