import { Box, Grid, Typography } from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

export default applyTo(
  ({ src, title, name, subtitle }) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box component="img" src={src} alt={name} style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h5">{name}</Typography>
          <Typography>{title}</Typography>
          <Typography>{subtitle}</Typography>
        </Grid>
      </Grid>
    );
  },
  pipe(
    propTypes({
      src: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      name: PropTypes.string,
    }),
    defaultProps({
      src: "",
      title: "",
      name: "",
      subtitle: "",
    }),
    displayName("TeamMember"),
    memo
  )
);
