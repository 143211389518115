const {
  REACT_APP_INFRA_NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_VERSION,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_USER_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_GA_TRACKING_ID,
} = process.env;

const envOkay = [
  REACT_APP_INFRA_NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_VERSION,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_USER_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_GA_TRACKING_ID,
].every((env) => env);
if (!envOkay)
  console.warn(
    "One or more env vars are missing. Please check your .env file in the project root."
  );

const conf = {
  API_HOST: REACT_APP_API_HOST,
  VERSION: REACT_APP_VERSION,
  env: REACT_APP_INFRA_NODE_ENV,
  GOOGLE_API_KEY: REACT_APP_GOOGLE_API_KEY,
  USER_POOL_ID: REACT_APP_USER_POOL_ID,
  COGNITO_CLIENT_ID: REACT_APP_COGNITO_CLIENT_ID,
  SENTRY_DSN: REACT_APP_SENTRY_DSN,
  GA_TRACKING_ID: REACT_APP_GA_TRACKING_ID,
};

export default conf;
