import { gql, useQuery } from "@apollo/client";
import { Person } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, head, join, map, path, pathOr, pick, pipe, values } from "ramda";
import { memo, useMemo } from "react";

const WHOAMI = gql`
    query WhoAmI {
        whoami {
            id
            firstName
            lastName
            avatarUrl
        }
    }
`;

// gql response -> string
export const getInitials = pipe(
    pathOr({}, ["whoami"]),
    pick(["firstName", "lastName"]),
    values,
    map(head),
    join(""),
);

export default applyTo(({ onClick }) => {
    const { data } = useQuery(WHOAMI);

    const initials = useMemo(() => {
        return getInitials(data);
    }, [data]);

    const avatarUrl = useMemo(() => {
        return path(["whoami", "avatarUrl"], data);
    }, [data]);

    if (avatarUrl) return <Avatar src={avatarUrl} />
    if (initials) return <Avatar>{initials}</Avatar>
    return <Avatar><Person /></Avatar>;
}, pipe(
    propTypes({
        onClick: PropTypes.func,
    }),
    defaultProps({
        onClick: () => { },
    }),
    displayName("UserAvatar"), memo));