import { Close } from "@mui/icons-material";
import { Alert, IconButton } from '@mui/material';
import { Stack } from "@mui/system";
import { path } from "ramda";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const LocationContext = createContext();

const LOCATION_PATHNAMES = ['/explore', '/locations'];

export const LocationProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [coordinate, setCoordinate] = useState(JSON.parse(window.localStorage.getItem('currentCoordinate'), null));
  const bannerDismissed = useMemo(() => !!window.localStorage.getItem('currentLocationAlertDismissed'), []);
  const [bannerVisible, setBannerVisible] = useState(LOCATION_PATHNAMES.includes(pathname) && !bannerDismissed && !coordinate);

  const requestCurrentLocation = useCallback(() => {
    const handleSuccess = (pos) => {
      const coordinate = {
        lat: path(["coords", "latitude"], pos),
        lng: path(["coords", "longitude"], pos),
      };
      setCoordinate(coordinate);
      window.localStorage.setItem('currentCoordinate', JSON.stringify(coordinate))
    };
    const handleError = (pos) => {
      console.log("error", pos);
      if (!bannerDismissed) setBannerVisible(true);
    }
    navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    });
  }, [setCoordinate, setBannerVisible, bannerDismissed]);

  const handleDismissAlert = useCallback(() => {
    window.localStorage.setItem('currentLocationAlertDismissed', true)
    setBannerVisible(false);
  }, [setBannerVisible]);

  useEffect(() => {
    // on mount of certain routes - request location
    if (!LOCATION_PATHNAMES.includes(pathname)) return;
    requestCurrentLocation()
  }, [pathname, requestCurrentLocation]);

  return (
    <LocationContext.Provider
      value={{
        currentLocation: coordinate,
        requestCurrentLocation,
      }}>
      {bannerVisible && (
        <Alert severity="warning" action={
          <Stack direction="row" spacing={2} alignItems='center'>
            {/* NOTE: once denied you cannot re-request location access - maybe add a learn more link to show how to re-enable via the browser? */}
            {/* {!isMobile && (
              <Button variant='text' startIcon={<MyLocation />} onClick={handleGetCurrentLocation}>
                Use Current Location
              </Button>
            )} */}
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleDismissAlert}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Stack>
        }
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          On Set! is better with your location!
          {/* NOTE: once denied you cannot re-request location access - maybe add a learn more link to show how to re-enable via the browser? */}
          {/* {isMobile && (
            <Button variant='outlined' endIcon={<MyLocation />} onClick={handleGetCurrentLocation} sx={{ height: 35, marginTop: 1 }}>
              Use Current Location
            </Button>
          )} */}
        </Alert>
      )}
      {children}
    </LocationContext.Provider>
  );
};

export const useCurrentLocation = () => useContext(LocationContext);
