import { CameraAlt } from "@mui/icons-material";
import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  applyTo,
  complement,
  filter,
  identity,
  isNil,
  map,
  match,
  path,
  pathOr,
  pipe,
  when,
} from "ramda";
import { memo, useCallback, useEffect, useMemo } from "react";
import ImageGallery from "react-image-gallery";

import Map from "components/Map";
import MapMarker from "components/MapMarker";
import { displayName } from "lib/react";

import { useReadQuery } from "@apollo/client";
import { useAppContext } from "components/AppContext";
import ContentfulRichText from "components/ContentfulRichText";
import SEO from "components/SEO";
import Youtube from "components/Youtube";
import conf from "conf";
import "react-image-gallery/styles/css/image-gallery.css";
import { Link } from "react-router-dom";
import Favorite from "./components/Favorite";
import Lists from "./components/Lists";
import MoreMenu from "./components/MoreMenu";
import RelatedLocations from "./components/RelatedLocations";
import VisitedButton from "./components/VisitedButton";

// String -> (String|null)
const getYoutubeId = when(
  identity,
  pipe(
    match(
      /(?<=v=|v\/|vi=|vi\/|youtu\.be\/|\/v\/|embed\/|\/u\/\w\/|user\/\w\/|watch\?v=)([^#&?]*).*/
    ),
    path([1])
  )
);

export default applyTo(({ queryRef }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { data } = useReadQuery(queryRef);
  const { setTitle, setHeaderContext, setBackPath } = useAppContext();

  useEffect(() => {
    setTitle(path(["location", "title"], data));
    setHeaderContext(
      <MoreMenu data={data} />
    );
    setBackPath('/explore')
  }, [data, setTitle, setHeaderContext, setBackPath]);

  const title = useMemo(() => path(["location", "title"], data), [data]);
  const address = useMemo(() => path(["location", "address"], data), [data]);
  const description = useMemo(
    () => path(["location", "description"], data),
    [data]
  );
  const geoLocation = useMemo(
    () => path(["location", "geoLocation"], data),
    [data]
  );
  const relatedLocations = useMemo(() => {
    return applyTo(
      data,
      pipe(
        pathOr([], ["location", "relatedLocations", "edges"]),
        map(path(["node"]))
      )
    );
  }, [data]);
  const show = useMemo(() => {
    return applyTo(
      data,
      pipe(
        pathOr([], ["location", "show", "edges"]),
        path([0, "node"]) // just get the first one for now
      )
    );
  }, [data]);
  const galleryItems = useMemo(() => {
    if (!data) return [];
    const sceneYoutubeId = applyTo(
      data,
      pipe(path(["location", "sceneUrl"]), when(identity, getYoutubeId))
    );
    const trailerYoutubeIds = applyTo(
      data,
      pipe(
        pathOr([], ["location", "show", "edges"]),
        map(pipe(path(["node", "trailerUrl"]), when(identity, getYoutubeId))),
        filter(complement(isNil))
      )
    );
    return [
      ...applyTo(
        data,
        pipe(
          pathOr([], ["location", "imageUrls"]),
          map((url) => ({
            original: url,
            thumbnail: url,
          }))
        )
      ),
      // scene url
      ...(sceneYoutubeId
        ? [
          {
            embedUrl: `https://www.youtube.com/embed/${sceneYoutubeId}`,
            thumbnail: `https://img.youtube.com/vi/${sceneYoutubeId}/0.jpg`,
            renderItem: (item) => <Youtube url={item.embedUrl} />,
          },
        ]
        : []),
      // trailer url(s)
      ...(trailerYoutubeIds
        ? trailerYoutubeIds.map((trailerYoutubeId) => ({
          embedUrl: `https://www.youtube.com/embed/${trailerYoutubeId}`,
          thumbnail: `https://img.youtube.com/vi/${trailerYoutubeId}/0.jpg`,
          renderItem: (item) => <Youtube url={item.embedUrl} />,
        }))
        : []),
    ];
  }, [data]);

  const handleScenePhotoClick = useCallback(() => {
    window.ReactNativeWebView.postMessage("launch-scene-photo");
  }, []);


  return (
    <Container>
      <SEO
        title={title}
        description={path(["location", "seo", "description"], data)}
        keywords={path(["location", "seo", "keywords"], data)}
      />
      <Stack gap={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Stack gap={1}>
              {!isMobile && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h3">{title}</Typography>
                  <Stack direction='row' gap={1}>
                    <Favorite data={data} />
                    <Lists data={data} />
                    <MoreMenu data={data} />
                  </Stack>
                </Stack>
              )}
              <Typography variant="h6">{address}</Typography>
              <ImageGallery items={galleryItems} />
              {description && <ContentfulRichText content={description} />}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack gap={2}>
              {/* Location information */}
              {geoLocation && (
                <Box
                  component="a"
                  href={`https://www.google.com/maps/@${geoLocation?.coordinate?.lat},${geoLocation?.coordinate?.lng},15z`}
                  target="_blank"
                  sx={{ width: "100%", height: 200 }}
                >
                  <Map center={geoLocation?.coordinate} interactive={false}>
                    <MapMarker geoLocation={geoLocation} />
                  </Map>
                </Box>
              )}

              {/* Lists */}
              <VisitedButton data={data} />

              {conf.env === "development" && (
                window.IS_REACT_NATIVE ? (
                  <Button
                    onClick={handleScenePhotoClick}
                    startIcon={<CameraAlt />}
                    fullWidth
                  >
                    Take a scene photo
                  </Button>
                ) : (
                  <Stack gap={1}>
                    <Button disabled startIcon={<CameraAlt />} fullWidth>
                      Take a scene photo
                    </Button>
                    <Typography variant="legal">
                      You must be on the mobile app to take a scene photo.
                    </Typography>
                  </Stack>
                )
              )}


              {/* Movie Details */}
              {show && (
                <>
                  <Typography variant="h4">As seen in:</Typography>
                  <Box component={Link} to={`/show/${show?.slug}`}>
                    <Box component="img" src={show?.posterUrl} width="100%" />
                  </Box>
                </>
              )}

              {/* How to visit */}
              {/* <Typography variant="h4">How to visit:</Typography>
                  <Stack direction='row' gap={1}>
                      <Chip label="Open to the Public" />
                      <Chip label="Tickets required" />
                      <Chip label="Tour available" />

                  </Stack>
                  <Typography variant="body1">Tickers are required to visit the philidelphia art museam.</Typography>
                  <Button component='a' href='#' startIcon={<LocalActivity />} fullWidth>Get Tickets</Button>
                  <Button component='a' href='#' startIcon={<LocalActivity />} fullWidth>Audio Tour</Button>
                  <Button component='a' href='#' startIcon={<LocalActivity />} fullWidth>View Tour Website</Button> */}
            </Stack>
          </Grid>
        </Grid>

        <RelatedLocations relatedLocations={relatedLocations} />
      </Stack>
    </Container >
  );
}, pipe(displayName("SetLocation"), memo));
