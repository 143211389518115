import { Container } from "@mui/material";
import { displayName } from "lib/react";
import { applyTo, pipe } from "ramda";
import { memo } from "react";
import { Outlet } from "react-router-dom";

export default applyTo(() => {
    return (
        <Container>
            <Outlet />
        </Container>
    );
}, pipe(displayName("ProfileFallback"), memo));
