import { applyTo, pipe } from "ramda";
import { memo, useEffect } from "react";

import { displayName } from "lib/react";
import { useLocation } from "react-router-dom";

export default applyTo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}, pipe(displayName("ScrollToTop"), memo));
