import { Check } from "@mui/icons-material";
import { Button } from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import { useList } from "lib/useList";
import { applyTo, pathOr, pipe } from "ramda";
import { memo } from "react";

export default applyTo(({ data }) => {
    const {
        toggle: handleToggleVisited,
        status: visited,
    } = useList({
        nodeId: pathOr(false, ["location", "id"], data),
        type: "VISITED",
        status: pathOr(false, ["location", "visited"], data)
    });

    return visited ? (
        <Button onClick={handleToggleVisited} startIcon={<Check />} fullWidth>Visited</Button>
    ) : (
        <Button onClick={handleToggleVisited} startIcon={<Check />} fullWidth>Mark as Visited</Button>
    );
}, pipe(propTypes({}), defaultProps({}), displayName("Favorite"), memo));
