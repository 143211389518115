import { gql, useMutation } from "@apollo/client";
import { ArrowBack } from "@mui/icons-material";
import { Alert, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { useAppContext } from "components/AppContext";
import { ListForm } from "components/forms";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, path, pipe } from "ramda";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CREATE_LIST = gql`
  mutation CreateList($input: CreateListInput!) {
    createList(input: $input) {
      id
    }
  }
`;

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [status, setStatus] = useState(null);
  const [createSubmission] = useMutation(CREATE_LIST);
  const navigate = useNavigate();
  const { setTitle, setBackPath } = useAppContext();

  useEffect(() => {
    setTitle("Create a list");
    setBackPath('/profile/lists')
  }, [setTitle, setBackPath]);

  const handleSubmit = useCallback(async (values) => {
    try {
      const result = await createSubmission({
        variables: {
          input: {
            ...values,
            items: [],
          },
        },
      });
      const listId = path(['data', 'createList', 'id'], result)
      setStatus({
        severity: "success",
        children: "List created",
      });
      navigate(`/profile/lists/${listId}`);
    } catch (err) {
      setStatus({
        severity: "error",
        children: err.message,
      });
    }
  }, [createSubmission, setStatus, navigate]);

  return (
    <Stack flexGrow={1} gap={2} sx={{ position: 'relative' }}>
      {!isMobile && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Stack direction="row" justifyContent="space-between" alignItems="center" >
              <IconButton onClick={() => navigate("/profile/lists")}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">Back</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Typography variant="h4">Create List</Typography>
          </Stack>
        </>
      )}
      {status && <Alert {...status} />}
      <ListForm onSubmit={handleSubmit} />
    </Stack >
  );
}, pipe(propTypes({}), defaultProps({}), displayName("CreateSubmission"), memo));
