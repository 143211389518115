import { Grid, Stack, Typography } from "@mui/material";
import SetLocationCard from "components/SetLocationCard/SetLocationCard";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, path, pathOr, pipe } from "ramda";
import { memo } from "react";

export default applyTo(({ relatedLocations }) => {

    if (relatedLocations.length === 0) {
        return null;
    }

    return (
        <Stack gap={2} sx={{ marginBottom: 2 }}>
            <Typography variant="h3">Related Locations</Typography>
            <Grid container spacing={2}>
                {relatedLocations.map((setLocation) => {
                    const title = path(["show", "edges", 0, "node", "title"], setLocation);
                    const year = path(["show", "edges", 0, "node", "year"], setLocation);
                    const subtitle = year ? `${title} (${year})` : title;
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={path(["id"], setLocation)}
                        >
                            <SetLocationCard
                                id={path(["id"], setLocation)}
                                favorite={path(["favorite"], setLocation)}
                                title={path(["title"], setLocation)}
                                subtitle={subtitle}
                                slug={path(["slug"], setLocation)}
                                imageUrls={pathOr([], ["imageUrls"], setLocation)} />
                        </Grid>
                    )
                })}
            </Grid>
        </Stack>
    );
}, pipe(propTypes({}), defaultProps({}), displayName("RelatedLocations"), memo));
