import { Box, Card, CardContent, Skeleton, Stack } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { displayName } from "lib/react";

export default applyTo(
  () => (
    <Card>
      {/* <CardMedia component={  } height={200} width="100%" translate="no" /> */}
      <Skeleton width="100%" height={200} />
      <CardContent variant="condensed">
        <Stack direction="row" alignItems="center">
          <Box sx={{ flexGrow: 1 }}>
            <Skeleton />
            <Skeleton width="50%" />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  ),
  pipe(displayName("SetLocationCardFallback"), memo)
);
