import { gql, useMutation, useReadQuery } from "@apollo/client";
import { ArrowBack } from "@mui/icons-material";
import { Button, Chip, Stack, Typography, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useAppContext } from "components/AppContext";
import { SubmissionForm } from "components/forms";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, path, pipe, prop } from "ramda";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const DELETE_SUBMISSION = gql`
  mutation DeleteSubmission($id: ID!) {
    deleteSubmission(id: $id) 
  }
`;

export default applyTo(({ queryRef }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const { data } = useReadQuery(queryRef);
  const [deleteSubmission] = useMutation(DELETE_SUBMISSION);
  const { setTitle, setHeaderContext, setBackPath } = useAppContext();

  const submission = useMemo(() => {
    return applyTo(data, pipe(
      prop("node"),
    ))
  }, [data]);

  const handleDeleteSubmission = useCallback(async () => {
    const id = path(['id'], submission);
    try {
      await deleteSubmission({
        variables: {
          id,
        },
      });
      navigate("/profile/submissions");
    } catch (error) {
      console.error(error);
    }
  }, [submission, navigate, deleteSubmission]);

  useEffect(() => {
    setTitle(path(['title'], submission));
    setHeaderContext(<Button variant="text" onClick={handleDeleteSubmission}>Delete</Button>);
    setBackPath('/profile/submissions')
  }, [setTitle, setHeaderContext, submission, handleDeleteSubmission, setBackPath]);

  return (
    <Stack flexGrow={1} gap={2} sx={{ position: 'relative' }}>
      {!isMobile && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Stack direction="row" justifyContent="space-between" alignItems="center" >
              <IconButton onClick={() => navigate("/profile/lists")}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">Back</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
              <Typography variant="h4">{path(['title'], submission)}</Typography>
              <Chip label={path(['status'], submission)} />
            </Stack>
            <Button onClick={handleDeleteSubmission}>Delete</Button>
          </Stack>
        </>
      )}
      <SubmissionForm initialValues={submission} />
    </Stack >
  );
}, pipe(propTypes({}), defaultProps({}), displayName("ViewSubmission"), memo));
