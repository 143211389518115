import { gql, useMutation } from "@apollo/client";
import { Modal, Paper, Stack, Typography, useMediaQuery, } from "@mui/material";
import { FlagContentForm } from "components/forms";
import { displayName } from "lib/react";
import { applyTo, pipe } from "ramda";
import { memo, useCallback } from "react";

const FLAG = gql`
  mutation Flag($input: FlagInput!) {
    flag(input: $input)
  }
`;

export default applyTo(({
    nodeId,
    open,
    onClose = () => { },
}) => {
    const isMobile = useMediaQuery("(max-width: 600px)");

    const [flag] = useMutation(FLAG);
    const handleSubmit = useCallback(async (values) => {
        try {
            await flag({
                variables: {
                    input: {
                        nodeId,
                        ...values,
                    }
                }
            });
            onClose();
        } catch (error) {
            console.error(error)
        }
    }, [flag, onClose, nodeId]);

    return (
        <Modal open={open} onClose={onClose} >
            <Stack component={Paper} gap={2} spacing={2} sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 4,
                width: '100%',
                maxWidth: isMobile ? '90%' : 500,
            }}>
                <Stack gap={1}>
                    <Typography variant="h4">Thanks for the heads up!</Typography>
                    <Typography variant="body1">We rely on our community to ensure data stays up to date.  So thank you for your flagging any issues you find.</Typography>
                </Stack>
                <FlagContentForm onSubmit={handleSubmit} />
            </Stack>
        </Modal>
    );
}, pipe(displayName("FlagModal"), memo));
