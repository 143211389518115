import { applyTo, pipe } from "ramda";
import { Suspense, useCallback, useEffect, useTransition } from "react";

import { gql, useBackgroundQuery } from "@apollo/client";
import { displayName } from "lib/react";

import { getInitialLocation } from "lib/location";
import { useLocation } from "react-router-dom";
import Content from "./Content";
import Fallback from "./Fallback";

const NEARBY_QUERY = gql`
  query SearchLocations($first: Int, $after: String, $input: SearchInput!) {
    search(first: $first, after: $after, input: $input) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          __typename
          ... on GeoLocation {
            id
            title
            setLocations {
              edges {
                node {
                  id
                  slug
                  title
                  imageUrls
                  show {
                    edges {
                      node {
                        id
                        title
                        year
                      }
                    }
                  }
                  favorite
                  geoLocation {
                    id
                    coordinate {
                      lat
                      lng
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// this coordinate comes in via the explore page
export default applyTo(({ coordinate: initCoordinate }) => {
  const { pathname } = useLocation();
  const [isPending, startTransition] = useTransition();
  const coordinate = initCoordinate || getInitialLocation();

  const [queryRef, { refetch, fetchMore }] = useBackgroundQuery(NEARBY_QUERY, {
    variables: {
      first: 9,
      input: {
        type: "COORDINATE",
        coordinate: coordinate,
      },
    },
  });

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  useEffect(() => {
    if (!initCoordinate) return;
    handleRefetch({
      variables: {
        first: 9,
        input: {
          type: "COORDINATE",
          coordinate: initCoordinate,
        },
      },
    });
  }, [initCoordinate, handleRefetch]);

  return (
    <Suspense fallback={<Fallback pathname={pathname} />}>
      <Content
        queryRef={queryRef}
        pathname={pathname}
        loading={isPending}
        refetch={handleRefetch}
        fetchMore={handleFetchMore}
        coordinate={coordinate}
      />
    </Suspense>
  );
}, pipe(displayName("Locations")));
