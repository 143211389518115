import { ArrowForward, ArrowRight } from "@mui/icons-material";
import {
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import SEO from "components/SEO";
import Youtube from "components/Youtube";
import { displayName } from "lib/react";
import TeamMemberGrid from "./components/TeamMemberGrid";

const ROADMAP = [
  {
    label: "Locations",
    text: "Add more locations (always)",
  },
  {
    label: "Community",
    text: "Create a space for location hunters to share their trips and share their experiences",
  },
  {
    label: "Feature",
    text: "Update mobile app to match re-branding.",
  },
  {
    label: "Feature",
    text: "Scene photos. Easily recreate the scene shot at a location and take a side-by-side photo.",
  },
  {
    label: "Feature",
    text: "Tours & Tickets. Easily book tours and tickets to visit locations.",
  },
  {
    label: "Feature",
    text: "Events. Allow the community to setup and share events focused around certain locations",
  },
  {
    label: "Content",
    text: "Create a mini-series documenting select movie's set locations",
  },
  {
    label: "Content",
    text: "Create a coffee table book with the most popular locations",
  },
];

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Container sx={{ marginBottom: isMobile ? 10 : 2 }}>
      <SEO title="About" />
      <Stack gap={isMobile ? 4 : 10}>
        <Typography variant="h2">Our Plot</Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} spacing={2}>
            <Stack gap={2}>
              <Typography variant="body1">
                A couple years ago, some friends and I spent the weekend in
                Sacramento for my birthday to visit the locations from The Birds
                and Scream. I did my research and spent some time on Google
                trying to find exactly where to go.
              </Typography>
              <Typography variant="body1">
                That's where the idea for On Set! came from. I wanted a simple
                way to make finding these locations easier than doing time
                intensive online searches.
              </Typography>
              <Typography variant="body1">
                - Joe Brunett (Co-Founder)
              </Typography>
              <Button
                endIcon={<ArrowForward />}
                component="a"
                sx={{ width: 250 }}
                href="https://www.kickstarter.com/projects/joeb/on-set"
              >
                View Kickstarter
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Youtube url="https://www.youtube.com/embed/YGJmymd0poA" />
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="h2">Our Storyboard</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5">On Set! App</Typography>
            <Typography>
              In 2020 we launched the initial version of our app helping users
              discover their favorite set locations.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5">On Screen! Podcast</Typography>
            <Typography>
              In 2021 we started growing out some film location related content
              with the premeir of our podcast "On Screen!".
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5">On Set! Website</Typography>
            <Typography>
              In 2023 we underwent a re-branding and launched a website along
              with an updated app.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h5">Roadmap 🚧</Typography>
            <Typography>
              We are actively developing On Set! to help serve and grow the
              movie location community. Our goal is to continously add new
              locations, features, and create content to engage and grow the
              community. Please reach out if you want to get involved!
            </Typography>
            <List>
              {ROADMAP.map(({ label, text }) => (
                <ListItem key={text}>
                  <ArrowRight />
                  {isMobile && <Chip label={label} sx={{ marginRight: 1 }} />}
                  <Typography variant="body1">{text}</Typography>
                </ListItem>
              ))}
            </List>
            <Typography>
              And much more! We are always looking for new ideas and ways to
              grow the community.
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <TeamMemberGrid />
      </Stack>
    </Container>
  );
}, pipe(displayName("About"), memo));
