import { gql, useMutation } from "@apollo/client";
import { ArrowBack } from "@mui/icons-material";
import { Alert, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { useAppContext } from "components/AppContext";
import { SubmissionForm } from "components/forms";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CREATE_SUBMISSION = gql`
  mutation CreateSubmission($input: CreateSubmissionInput!) {
    createSubmission(input: $input) {
      id
    }
  }
`;

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [status, setStatus] = useState(null);
  const [createSubmission] = useMutation(CREATE_SUBMISSION);
  const navigate = useNavigate();
  const { setTitle, setBackPath } = useAppContext();

  useEffect(() => {
    setTitle("Submit a Set Location");
    setBackPath('/profile/submissions')
  }, [setTitle, setBackPath]);

  const handleSubmit = useCallback(async (values) => {
    try {
      await createSubmission({
        variables: {
          input: values,
        },
      });
      setStatus({
        severity: "success",
        children: "Submission received!",
      });
      navigate("/profile/submissions");
    } catch (err) {
      setStatus({
        severity: "error",
        children: err.message,
      });
    }
  }, [createSubmission, setStatus, navigate]);

  return (
    <Stack flexGrow={1} gap={2} sx={{ position: 'relative' }}>
      {!isMobile && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Stack direction="row" justifyContent="space-between" alignItems="center" >
              <IconButton onClick={() => navigate("/profile/submissions")}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">Back</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Typography variant="h4">Submit a Set Location</Typography>
          </Stack>
        </>
      )}
      {status && <Alert {...status} />}
      <SubmissionForm onSubmit={handleSubmit} />
    </Stack >
  );
}, pipe(propTypes({}), defaultProps({}), displayName("CreateSubmission"), memo));
