import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";
import { applyTo, pipe, prop, propOr } from "ramda";
import { memo } from "react";

import { defaultProps, displayName, propTypes } from "lib/react";

export default applyTo(
  ({ className, label, rows, helperText, ...rest }) => {
    const [field, meta] = useField(rest);
    return (
      <FormControl
        fullWidth={propOr(true, "fullWidth", rest)}
        className={className}
      >
        <FormControlLabel
          label={label}
          labelPlacement="left"
          helperText={propOr(helperText, "error", meta)}
          error={!!(prop("touched", meta) && prop("error", meta))}
          control={
            <Switch
              {...field}
              {...rest}
            />
          }
        />
      </FormControl>
    );
  },
  pipe(
    propTypes({
      className: PropTypes.string,
      label: PropTypes.string,
      rows: PropTypes.number,
    }),
    defaultProps({
      className: null,
      label: null,
      rows: null,
    }),
    displayName("TextField"),
    memo
  )
);
