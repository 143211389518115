import { Grid, Skeleton, Stack } from "@mui/material";
import { displayName } from "lib/react";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

export default applyTo(() => {
  return (
    <Stack gap={4}>
      <Stack flexGrow={1} gap={1}>
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6].map((idx) => (
            <Grid item xs={6} md={4} key={`search-fallback-${idx}`}>
              <Skeleton sx={{ paddingBottom: "160%" }} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}, pipe(displayName("SearchResultsFallback"), memo));
