import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import config from "conf";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";
import App from "./views/App";

// TODO: upload source maps npx @sentry/wizard@latest -i sourcemaps
Sentry.init({
  dsn: config.SENTRY_DSN,
  // TODO: need to allow sentry-trace and baggage headers at the API Gatway level for this to work
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [config.API_HOST],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: config.env === 'dev' ? 1.0 : 0.25,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: config.env,
  enabled: config.env !== 'local',
});

Amplify.configure({
  region: "us-east-1",
  Auth: {
    region: "us-east",
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_CLIENT_ID,
  },
});

ReactGA.initialize(config.GA_TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
