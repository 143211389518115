import config from "conf";
import { createBrowserRouter, Navigate } from "react-router-dom";

import ErrorElement from "components/ErrorElement";
import Layout from "components/Layout";
import About from "views/About";
import Blog from "views/Blog";
import Blogs from "views/Blogs";
import Contact from "views/Contact";
import Discover from "views/Discover";
import Explore from "views/Explore";
import ForgotPassword from "views/ForgotPassword";
import Home from "views/Home";
import Locations from "views/Locations";
import Login from "views/Login";
import Logout from "views/Logout";
import NotFound from "views/NotFound";
import Privacy from "views/Privacy";
import Profile from "views/Profile";
import Favorites from "views/Profile/components/Favorites";
import Lists from "views/Profile/components/Lists";
import CreateList from "views/Profile/components/Lists/components/CreateList";
import ViewList from "views/Profile/components/Lists/components/ViewList";
import Premium from "views/Profile/components/Premium";
import Settings from "views/Profile/components/Settings";
import Submissions from "views/Profile/components/Submissions";
import CreateSubmission from "views/Profile/components/Submissions/components/CreateSubmission";
import ViewSubmission from "views/Profile/components/Submissions/components/ViewSubmission";
import Visited from "views/Profile/components/Visited";
import PublicProfile from "views/PublicProfile";
import Search from "views/Search";
import SetLocation from "views/SetLocation";
import SetupProfile from "views/SetupProfile";
import Show from "views/Show";
import Signup from "views/Signup";
import Terms from "views/Terms";

const DEV_ONLY_ROUTES =
  config.env !== "production"
    ? [
      {
        path: "/discover",
        element: <Discover />,
        errorElement: <ErrorElement />,
      },
    ]
    : [];

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      ...DEV_ONLY_ROUTES,
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/explore",
        element: <Explore />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/locations",
        element: <Locations />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/location/:slug",
        element: <SetLocation />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/show/:slug",
        element: <Show />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/search",
        element: <Search />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/setup",
        element: <SetupProfile />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/profile",
        element: <Profile />,
        errorElement: <ErrorElement />,
        children: [
          {
            index: true,
            element: <Navigate to="/profile/favorites" />
          },
          {
            path: "favorites",
            element: <Favorites />,
            errorElement: <ErrorElement />,
          },
          {
            path: "visited",
            element: <Visited />,
            errorElement: <ErrorElement />,
          },
          {
            path: "submissions",
            children: [
              {
                index: true,
                element: <Submissions />,
                errorElement: <ErrorElement />,
              },
              {
                path: "create",
                element: <CreateSubmission />,
                errorElement: <ErrorElement />,
              },
              {
                path: ":id",
                element: <ViewSubmission />,
                errorElement: <ErrorElement />,
              },
            ],
          },
          {
            path: "lists",
            children: [
              {
                index: true,
                element: <Lists />,
                errorElement: <ErrorElement />,
              },
              {
                path: "create",
                element: <CreateList />,
                errorElement: <ErrorElement />,
              },
              {
                path: ":id",
                element: <ViewList />,
                errorElement: <ErrorElement />,
              },
            ],
          },
          {
            path: "lists",
            element: <Profile defaultTab="list" />,
            errorElement: <ErrorElement />,
          },
          ...config.env !== "production" ? [{
            path: "premium",
            element: <Premium />,
            errorElement: <ErrorElement />,
          }] : [{}],
          {
            path: "settings",
            element: <Settings />,
            errorElement: <ErrorElement />,
          },
          {
            path: "submission/:id",
            element: <Profile defaultTab="settings" />,
            errorElement: <ErrorElement />,
          },
          {
            path: "list/:id",
            element: <Profile defaultTab="list" />,
            errorElement: <ErrorElement />,
          },
        ],
      },
      {
        path: "/user",
        errorElement: <ErrorElement />,
        children: [
          {
            path: ":username",
            element: <PublicProfile />,
            errorElement: <ErrorElement />,
          }
        ],
      },
      {
        path: "/blog",
        element: <Blogs />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/blog/:slug",
        element: <Blog />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/about",
        element: <About />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/contact",
        element: <Contact />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/terms-and-conditions",
        element: <Terms />,
        errorElement: <ErrorElement />,
      },
      {
        path: "/privacy-policy",
        element: <Privacy />,
        errorElement: <ErrorElement />,
      },
      {
        path: "*",
        element: <NotFound />,
        errorElement: <ErrorElement />,
      },
    ],
  },
]);

export default router;
