import { gql, useMutation } from '@apollo/client';
import { useAuth } from 'components/AuthContext';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TOGGLE_LIST_ITEM = gql`
  mutation ToggleListItem($input: ToggleListItemInput!) {
    toggleListItem(input: $input)
  }
`;

export const useList = ({
    nodeId,
    listId = null,
    type = "CUSTOM",
    status: initStatus,
}) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [toggleListItem] = useMutation(TOGGLE_LIST_ITEM);
    const [status, setStatus] = useState(initStatus || false);

    const handleToggle = useCallback(async () => {
        if (!isAuthenticated) {
            // TODO: prompt user to login
            navigate('/login');
            return;
        }
        // toggle the btn immediately, (can be reverted if the mutation fails)
        setStatus(!status);
        try {
            await toggleListItem({
                variables: {
                    input: {
                        type,
                        nodeId,
                        listId,
                    }
                }
            });
        } catch (error) {
            // if there was an error revert the state
            setStatus(status);
        }
    }, [navigate, setStatus, isAuthenticated, toggleListItem, status, type, listId, nodeId]);

    const content = useMemo(() => ({
        toggle: handleToggle,
        status,
    }), [handleToggle, status]);

    return content;
};