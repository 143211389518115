import { applyTo, pipe } from "ramda";
import { Suspense, memo } from "react";

import { gql, useBackgroundQuery } from "@apollo/client";
import { displayName } from "lib/react";
import { useParams } from "react-router-dom";

import Content from "./Content";
import Fallback from "./Fallback";

const BLOG_QUERY = gql`
  query BlogQuery($slug: String!) {
    blog(slug: $slug) {
      id
      title
      content
    }
  }
`;

export default applyTo(() => {
  const { slug } = useParams();
  const [queryRef] = useBackgroundQuery(BLOG_QUERY, {
    variables: { slug },
  });

  return (
    <Suspense fallback={<Fallback />}>
      <Content queryRef={queryRef} />
    </Suspense>
  );
}, pipe(displayName("Blog"), memo));
