import { GoogleMap, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import config from "conf";
import { defaultProps, displayName, propTypes } from "lib/react";
import useDebouncedCallback from "lib/useDebouncedCallback";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { memo, useCallback, useState } from "react";

import mapStyles from "./styles.json";

const libs = ['places'];
export default applyTo(
  ({ children, center, onBoundsChanged, onCenterChanged, interactive }) => {
    const [showAddLocation, setShowAddLocation] = useState(false);
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: config.GOOGLE_API_KEY,
      libraries: libs,
    });

    const [map, setMap] = useState(null);

    const handleLoad = useCallback(
      (map) => {
        setMap(map);
        if (!map) return;
        // get intial bounds so we can make the initial query
        const bounds = map.getBounds(); // Map -> LatLngBounds
        if (!bounds) return;
        const neBounds = bounds.getNorthEast();
        const swBounds = bounds.getSouthWest();
        onBoundsChanged({
          // convert to shape used by graph.
          topLeftLat: neBounds.lat(),
          topLeftLng: swBounds.lng(),
          bottomRightLat: swBounds.lat(),
          bottomRightLng: neBounds.lng(),
        });
      },
      [setMap, onBoundsChanged]
    );

    const handleMapClick = useCallback(
      (mapEvent) => {
        const position = {
          lat: mapEvent.latLng.lat(),
          lng: mapEvent.latLng.lng(),
        };
        setShowAddLocation(position);
      },
      [setShowAddLocation]
    );

    const handleAddLocationClose = useCallback(() => {
      setShowAddLocation(null);
    }, [setShowAddLocation]);

    const handleBoundsChanged = useDebouncedCallback(() => {
      if (!map) return;
      const bounds = map.getBounds(); // Map -> LatLngBounds
      const neBounds = bounds.getNorthEast();
      const swBounds = bounds.getSouthWest();
      onBoundsChanged({
        // convert to shape used by graph.
        topLeftLat: neBounds.lat(),
        topLeftLng: swBounds.lng(),
        bottomRightLat: swBounds.lat(),
        bottomRightLng: neBounds.lng(),
      });
    }, 500);

    const handleCenterChanged = useDebouncedCallback(() => {
      if (!map) return;
      onCenterChanged({
        lat: map.center.lat(),
        lng: map.center.lng(),
      });
    }, 500);

    const handleUnmount = useCallback((_) => setMap(null), []);

    if (!isLoaded) return null;

    return (
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        options={{
          backgroundColor: "#FDF9ED",
          styles: mapStyles,
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          gestureHandling: interactive ? "auto" : "none",
        }}
        center={center}
        zoom={10}
        onDblClick={handleMapClick}
        onLoad={handleLoad}
        onUnmount={handleUnmount}
        onCenterChanged={handleCenterChanged}
        onBoundsChanged={handleBoundsChanged}
      >
        {children}
        {showAddLocation && (
          <InfoWindow
            onCloseClick={handleAddLocationClose}
            position={showAddLocation}
          >
            {/* <Link href={`/submission?position=${encodeURIComponent(JSON.stringify(showAddLocation))}`}>Submit Location Here.</Link> */}
          </InfoWindow>
        )}
      </GoogleMap>
    );
  },
  pipe(
    propTypes({
      center: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      children: PropTypes.node,
      onCenterChanged: PropTypes.func,
      onBoundsChanged: PropTypes.func,
      onMapCenterChanged: PropTypes.func,
      interactive: PropTypes.bool,
    }),
    defaultProps({
      center: {
        // NYC
        lat: 40.7128,
        lng: -74.006,
      },
      children: null,
      onCenterChanged: () => { },
      onBoundsChanged: () => { },
      onMapCenterChanged: () => { },
      interactive: true,
    }),
    displayName("Map"),
    memo
  )
);
