import PropTypes from "prop-types";
import { applyTo, path, pipe, prop, propOr } from "ramda";

import { FormControl, TextField } from "@mui/material";
import { Autocomplete as GooglePlacesAutocomplete, useJsApiLoader } from '@react-google-maps/api';
import conf from "conf";
import { useField } from "formik";
import { defaultProps, displayName, propTypes } from "lib/react";
import { memo, useCallback, useState } from 'react';

const AddressField = applyTo(
  ({ className, label, rows, helperText, ...rest }) => {
    const [result, setResult] = useState('')
    const [field, meta, { setValue, setTouched }] = useField(rest);

    const handleLoad = useCallback((autocomplete) => {
      setResult(autocomplete);
    }, [setResult])

    const handleChange = useCallback((place) => {
      if (result != null) {
        const place = result.getPlace();
        setValue(path(['formatted_address'], place));
        setTouched(true);
      } else {
        alert("Please enter text");
      }
    }, [result, setValue, setTouched])

    return (
      <FormControl
        fullWidth={true}
        sx={{ '& > div': { width: '100%' } }}
      >
        <GooglePlacesAutocomplete onLoad={handleLoad} onPlaceChanged={handleChange}>
          <TextField
            fullWidth={true}
            {...field}
            {...rest}
            label={label}
            error={!!(prop("touched", meta) && prop("error", meta))}
            helperText={propOr(helperText, "error", meta)} />
        </GooglePlacesAutocomplete >
      </ FormControl>
    )
  },
  pipe(
    propTypes({
      className: PropTypes.string,
      label: PropTypes.string,
      rows: PropTypes.number,
    }),
    defaultProps({
      className: null,
      label: null,
      rows: null,
    }),
    displayName("TextField"),
    memo,
  )
);

const libs = ['places'];

export default applyTo((props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: conf.GOOGLE_API_KEY,
    libraries: libs,
  });

  if (!isLoaded) return null;
  return (
    <AddressField {...props} />
  )
}, pipe(
  memo,
))