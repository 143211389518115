import { Container, Grid, Skeleton, Stack } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { displayName } from "lib/react";

export default applyTo(() => {
    return (
        <Container>
            <Stack gap={4}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Stack gap={1}>
                            <Skeleton width="100%" height={50} />
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
}, pipe(displayName("ProfileFallback"), memo));
