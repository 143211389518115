import { useReadQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { displayName } from "lib/react";
import {
  applyTo,
  filter,
  map,
  not,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
} from "ramda";
import { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

const PAGE_SIZE = 6;

export default applyTo(({ queryRef, fetchMore, isPending, query, contentType }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const { data } = useReadQuery(queryRef)

  const results = useMemo(() => {
    if (!data) return [];
    return applyTo(
      data,
      pipe(
        pathOr([], ["search", "edges"]),
        map(prop("node")),
        filter(pipe(propEq("__typename", "Show"), not))
      )
    );
  }, [data]);

  const pageInfo = useMemo(() => {
    return pathOr({
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    }, ["search", "pageInfo"], data);
  }, [data]);

  const hasNextPage = useMemo(() => {
    return pathOr(false, ["hasNextPage"], pageInfo);
  }, [pageInfo]);

  const handleLoadMore = useCallback(async () => {
    fetchMore({
      variables: {
        first: PAGE_SIZE,
        after: path(["endCursor"], pageInfo),
        input: {
          type: "TEXT",
          textSearch: {
            query,
            contentType,
          },
        },
      },
    });
  }, [fetchMore, pageInfo, contentType, query]);

  return (
    <Stack>
      <Stack flexGrow={1} gap={2}>
        <Stack gap={5}>
          {results.length > 0 && (
            <Grid container spacing={2}>
              {contentType === "SHOW" &&
                results.map(({ slug, posterUrl, id }) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      key={id}
                      component={Link}
                      to={`/show/${slug}`}
                    >
                      <Box
                        component='img'
                        src={`${posterUrl}?w=300&h=500&fit=fill&fl=progressive`}
                        width='100%'
                      />
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Stack>
        {data && results.length === 0 && (
          <Box
            flexGrow={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4">No Results</Typography>
          </Box>
        )}
        {query !== "" && results.length > 0 && hasNextPage && (
          <Button
            onClick={handleLoadMore}
            sx={!isMobile ? { width: 250, alignSelf: "center" } : {}}
          >
            <Stack direction="row" gap={2} alignItems="center">
              <CircularProgress
                size={16}
                sx={{ display: isPending ? "block" : "none" }}
              />
              Load more
            </Stack>
          </Button>
        )}
        {results.length > 0 && !hasNextPage && (
          <Typography variant="body1" textAlign="center">
            No more results
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}, pipe(displayName("Search"), memo));
