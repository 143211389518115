import { useReadQuery } from "@apollo/client";
import { Button, Chip, CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { useAppContext } from "components/AppContext";
import { format, parseISO } from "date-fns";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, map, path, pathOr, pipe, prop, } from "ramda";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

export default applyTo(({ queryRef, fetchMore, loading }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { data } = useReadQuery(queryRef);
  const { setTitle, setHeaderContext, setBackPath } = useAppContext();

  useEffect(() => {
    setTitle("");
    setHeaderContext(<Button variant="text" component={Link} to='/profile/submissions/create'>Submit Location</Button>);
    setBackPath(null);
  }, [setTitle, setHeaderContext, setBackPath]);

  const submissions = useMemo(() => {
    return applyTo(data, pipe(
      pathOr([], ["whoami", "submissions", "edges"]),
      map(prop("node")),
    ))
  }, [data]);

  const pageInfo = useMemo(() => {
    return pathOr({
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    }, ["whoami", "submissions", "pageInfo"], data);
  }, [data]);

  const hasNextPage = useMemo(() => {
    return pathOr(false, ["hasNextPage"], pageInfo);
  }, [pageInfo]);

  const handleLoadMore = useCallback(async () => {
    fetchMore({
      variables: {
        first: 5,
        after: path(["endCursor"], pageInfo),
      },
    });
  }, [fetchMore, pageInfo]);

  const handleRowClick = useCallback((submission) => () => {
    navigate(`/profile/submissions/${path(['id'], submission)}`)
  }, [navigate]);

  if (submissions.length === 0) return (
    <Stack spacing={2} justifyContent='center' alignItems='center' flexGrow={1}>
      <Typography variant='h3'>Nothing here yet.</Typography>
      <Typography variant='body1' textAlign='center'>When you submit a location, it will show up here.</Typography>
      <Button component={Link} to='/profile/submissions/create'>Submit Location</Button>
    </Stack>
  );

  return (
    <Stack flexGrow={1} gap={2} sx={{ position: 'relative' }}>
      {!isMobile && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" >
          <Typography variant="h4">Submissions</Typography>
          <Button component={Link} to='/profile/submissions/create'>Submit Location</Button>
        </Stack>
      )}

      <Table>
        <TableHead>
          <TableRow>
            {!isMobile && (
              <TableCell>
                <Typography variant="body1"></Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography variant="body1">Title</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">Address</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">Status</Typography>
            </TableCell>
            {!isMobile && (
              <TableCell>
                <Typography variant="body1">Submit Date</Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {submissions.map((submission, idx) => (
            <TableRow onClick={handleRowClick(submission)} key={path(['id'], submission)} sx={{ textDecoration: 'none' }}>
              {!isMobile && (
                <TableCell>
                  <Typography variant="body1">{idx + 1}</Typography>
                </TableCell>
              )}
              <TableCell>
                <Typography variant="body1">{path(['title'], submission)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{path(['address'], submission)}</Typography>
              </TableCell>
              <TableCell>
                <Chip label={path(['status'], submission)} sx={{ marginRight: 1 }} />
              </TableCell>
              {!isMobile && (
                <TableCell>
                  <Typography variant="body1">{format(parseISO(path(['createdAt'], submission)), 'M/dd/yyyy')}</Typography>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {hasNextPage && (
        <Button
          onClick={handleLoadMore}
          sx={!isMobile ? { width: 250, alignSelf: "center" } : {}}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <CircularProgress
              size={16}
              sx={{ display: loading ? "block" : "none" }}
            />
            Load more
          </Stack>
        </Button>
      )}
    </Stack >
  );
}, pipe(propTypes({}), defaultProps({}), displayName("SubmissionsContent"), memo));
