import { Button, CircularProgress, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  applyTo,
  filter,
  flatten,
  map,
  not,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
} from "ramda";
import { memo, useCallback, useMemo } from "react";

import { useReadQuery } from "@apollo/client";
import SEO from "components/SEO";
import SetLocationCard from "components/SetLocationCard";
import { distanceBetween } from "lib/location";
import { displayName } from "lib/react";

export default applyTo(({ queryRef, pathname, coordinate, fetchMore, loading }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { data } = useReadQuery(queryRef);

  const setLocations = useMemo(() => {
    if (!data) return [];
    return applyTo(
      data,
      pipe(
        pathOr([], ["search", "edges"]),
        map(prop("node")),
        filter(pipe(propEq("__typename", "GeoLocation"), not)),
        map(pipe(prop("setLocations"), prop("edges"))),
        flatten,
        map(prop("node"))
      )
    );
  }, [data]);

  const pageInfo = useMemo(() => {
    return pathOr({
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    }, ["search", "pageInfo"], data);
  }, [data]);

  const hasNextPage = useMemo(() => {
    return pathOr(false, ["hasNextPage"], pageInfo);
  }, [pageInfo]);

  const handleLoadMore = useCallback(async () => {
    fetchMore({
      variables: {
        first: 6,
        after: path(["endCursor"], pageInfo),
      },
    });
  }, [fetchMore, pageInfo]);

  // on explore page only show 1 column regardless of screen size
  const props = pathname !== "/explore" ? { sm: 4 } : { md: 12 };

  if (setLocations.length === 0) {
    return (
      <Stack spacing={2} justifyContent="center" alignItems="center" flexGrow={1} padding={2}>
        <Typography variant="h3" textAlign='center'>No locations in this region.</Typography>
        <Typography variant="body1" textAlign='center'>
          Move the map around to find locations in other regions.
        </Typography>
      </Stack>
    )
  }

  return (
    <Container >
      {isMobile && <SEO title="Nearby locations" />}
      <Stack gap={2} paddingBottom={2}>
        <Typography variant="h3">Nearby locations</Typography>
        <Grid container spacing={2}>
          {setLocations.map((setLocation) => {
            const locationCoordinate = path(['geoLocation', 'coordinate'], setLocation);
            const distance = `${distanceBetween(coordinate, locationCoordinate).toFixed(2)} miles`
            const showTitle = path(
              ["show", "edges", 0, "node", "title"],
              setLocation
            );
            const year = path(
              ["show", "edges", 0, "node", "year"],
              setLocation
            );
            const showText = year ? `${showTitle} (${year})` : showTitle;
            return (
              <Grid item xs={12} {...props} key={path(["id"], setLocation)}>
                <SetLocationCard
                  id={path(["id"], setLocation)}
                  favorite={path(["favorite"], setLocation)}
                  title={path(["title"], setLocation)}
                  subtitle={showText}
                  distance={distance}
                  slug={path(["slug"], setLocation)}
                  imageUrls={pathOr([], ["imageUrls"], setLocation)}
                />
              </Grid>
            );
          })}
        </Grid>
        {hasNextPage && (
          <Button
            onClick={handleLoadMore}
            sx={!isMobile ? { width: 250, alignSelf: "center" } : {}}
          >
            <Stack direction="row" gap={2} alignItems="center">
              <CircularProgress
                size={16}
                sx={{ display: loading ? "block" : "none" }}
              />
              Load more
            </Stack>
          </Button>
        )}
      </Stack>
    </Container>
  );
}, pipe(displayName("Locations"), memo));
