import { gql, useMutation, useReadQuery } from "@apollo/client";
import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress, Grid, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { useAppContext } from "components/AppContext";
import SetLocationCard from "components/SetLocationCard/SetLocationCard";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, filter, map, not, path, pathEq, pathOr, pipe, prop } from "ramda";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const DELETE_LIST = gql`
  mutation DeleteList($id: ID!) {
    deleteList(id: $id) 
  }
`;

export default applyTo(({ queryRef, loading, fetchMore }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const { data } = useReadQuery(queryRef);
  const [deleteList] = useMutation(DELETE_LIST);
  const { setTitle, setHeaderContext, setBackPath } = useAppContext();

  const list = useMemo(() => {
    return applyTo(data, pipe(
      prop("node"),
    ))
  }, [data]);

  const handleDeleteList = useCallback(async () => {
    const id = path(['id'], list);
    try {
      await deleteList({
        variables: {
          id,
        },
      });
      navigate("/profile/lists");
    } catch (error) {
      console.error(error);
    }
  }, [list, navigate, deleteList]);

  useEffect(() => {
    setTitle(path(['title'], list));
    setHeaderContext(<Button variant="text" onClick={handleDeleteList}>Delete</Button>);
    setBackPath('/profile/lists')
  }, [setTitle, setHeaderContext, handleDeleteList, list, setBackPath]);

  const setLocations = useMemo(() => {
    return applyTo(list, pipe(
      pathOr([], ['items', 'edges']),
      map(prop('node')),
      filter(pipe(pathEq(['__typename'], 'SetLocation'), not)),
    ))
  }, [list]);


  const pageInfo = useMemo(() => {
    return pathOr({
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    }, ["node", "items", "pageInfo"], data);
  }, [data]);

  const hasNextPage = useMemo(() => {
    return pathOr(false, ["hasNextPage"], pageInfo);
  }, [pageInfo]);

  const handleLoadMore = useCallback(async () => {
    fetchMore({
      variables: {
        first: 6,
        after: path(["endCursor"], pageInfo),
      },
    });
  }, [fetchMore, pageInfo]);

  return (
    <Stack flexGrow={1} gap={2} paddingBottom={2} sx={{ position: 'relative' }}>
      {!isMobile && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Stack direction="row" justifyContent="space-between" alignItems="center" >
              <IconButton onClick={() => navigate("/profile/lists")}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">Back</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" >
            <Typography variant="h4">{path(['title'], list)}</Typography>
            <Button onClick={handleDeleteList}>Delete</Button>
          </Stack>
        </>
      )}
      <Grid container spacing={2}>
        {setLocations.map((setLocation) => {
          const title = path(["show", "edges", 0, "node", "title"], setLocation);
          const year = path(["show", "edges", 0, "node", "year"], setLocation);
          const subtitle = year ? `${title} (${year})` : title;
          return (
            <Grid item xs={12} sm={6} md={4} key={path(["id"], setLocation)}>
              <SetLocationCard
                id={path(["id"], setLocation)}
                favorite={path(["favorite"], setLocation)}
                title={path(["title"], setLocation)}
                subtitle={subtitle}
                slug={path(["slug"], setLocation)}
                imageUrls={pathOr([], ["imageUrls"], setLocation)}
              />
            </Grid>
          );
        })}
      </Grid>
      {hasNextPage && (
        <Button
          onClick={handleLoadMore}
          sx={!isMobile && { width: 250, alignSelf: "center" }}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <CircularProgress
              size={16}
              sx={{ display: loading ? "block" : "none" }}
            />
            Load more
          </Stack>
        </Button>
      )}
    </Stack >
  );
}, pipe(propTypes({}), defaultProps({}), displayName("ViewList"), memo));
