import { useReadQuery } from "@apollo/client";
import { Button, CircularProgress, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import SetLocationCard from "components/SetLocationCard/SetLocationCard";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, head, map, path, pathOr, pipe } from "ramda";
import { memo, useCallback, useMemo } from "react";

export default applyTo(({ queryRef, fetchMore, loading }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { data } = useReadQuery(queryRef);

  const nodes = useMemo(() => {
    return applyTo(data, pipe(
      pathOr({}, ["whoami"]),
      pathOr([], ["visited", "edges"]),
      head,
      pathOr([], ["node", "items", "edges"]),
      map(({ node }) => node),
    ));
  }, [data]);

  const pageInfo = useMemo(() => {
    return pathOr({
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    }, ["whoami", "visited", "edges", 0, "node", "items", "pageInfo"], data);
  }, [data]);

  const hasNextPage = useMemo(() => {
    return pathOr(false, ["hasNextPage"], pageInfo);
  }, [pageInfo]);

  const handleLoadMore = useCallback(async () => {
    fetchMore({
      variables: {
        first: 6,
        after: path(["endCursor"], pageInfo),
      },
    });
  }, [fetchMore, pageInfo]);

  if (nodes.length === 0) return (
    <Stack spacing={2} justifyContent='center' alignItems='center' flexGrow={1}>
      <Typography variant='h3'>Nothing here yet.</Typography>
      <Typography variant='body1' textAlign='center'>When you mark a location as visited, it will show up here.</Typography>
    </Stack>
  );

  return (
    <Stack gap={2} paddingBottom={2} width='100%'>
      <Grid container spacing={2}>
        {nodes.map((setLocation) => {
          const title = path(
            ["show", "edges", 0, "node", "title"],
            setLocation
          );
          const year = path(["show", "edges", 0, "node", "year"], setLocation);
          const subtitle = year ? `${title} (${year})` : title;
          return (
            <Grid item xs={12} sm={6} md={4} key={path(["id"], setLocation)}>
              <SetLocationCard
                id={path(["id"], setLocation)}
                favorite={path(["favorite"], setLocation)}
                title={path(["title"], setLocation)}
                subtitle={subtitle}
                slug={path(["slug"], setLocation)}
                imageUrls={pathOr([], ["imageUrls"], setLocation)}
              />
            </Grid>
          );
        })}
      </Grid>
      {hasNextPage && (
        <Button
          onClick={handleLoadMore}
          sx={!isMobile ? { width: 250, alignSelf: "center" } : {}}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <CircularProgress
              size={16}
              sx={{ display: loading ? "block" : "none" }}
            />
            Load more
          </Stack>
        </Button>
      )}
    </Stack>
  );
}, pipe(propTypes({}), defaultProps({}), displayName("VisitedContent"), memo));
