import { gql, useLazyQuery } from "@apollo/client";
import { applyTo, concat, map, path, pathOr, pipe, uniqBy } from 'ramda';
import { useCallback, useState } from 'react';

export const SEARCH_FILM_LOCATIONS = gql`
query SearchLocations($first: Int, $after: String, $input: SearchInput!) {
  search(first: $first, after: $after, input: $input) {
    edges {
      node {
        __typename
        ... on GeoLocation {
          id
          title
          address
          coordinate {
            lat
            lng
          }
          setLocations {
            edges {
              node {
                id
                slug
                title
                imageUrls
                show {
                  edges {
                    node {
                      id
                      title
                      year
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const useLocationSearch = (initialGeoLocations = []) => {
  const [geoLocations, setGeoLocations] = useState(initialGeoLocations);

  const handleCompleted = useCallback((data) => {
    // geo locations
    const newGeoLocations = applyTo(data, pipe(
      pathOr([], ['search', 'edges']),
      map(path(['node'])),
    ));
    const allGeoLocations = applyTo(newGeoLocations, pipe(
      // put them at the top of the list
      newLocs => concat(newLocs, geoLocations),
      uniqBy(path(['id'])),
    ));
    setGeoLocations(allGeoLocations);
  }, [setGeoLocations, geoLocations]);

  // TODO: query api based on bounds / filters
  const [searchLocations, { loading, error }] = useLazyQuery(SEARCH_FILM_LOCATIONS, {
    onCompleted: handleCompleted,
  });

  if (error) throw Error(error.message);

  return [
    searchLocations,
    {
      loading,
      error,
      geoLocations,
    },
  ];
};

export default useLocationSearch;