const NYC = {
  lat: 40.7128,
  lng: -74.006,
};

export const getInitialLocation = () => {
  if (typeof window !== "undefined") {
    const coordinate = JSON.parse(localStorage.getItem("coordinate") || null);
    console.log(coordinate);
    if (coordinate && coordinate.lat && coordinate.lng) return coordinate;
  }
  return NYC;
};

export const kilometersToMiles = (kilometers) => {
  const conversionFactor = 0.621371;
  const miles = kilometers * conversionFactor;
  return miles;
}

export const distanceBetween = ({ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }) => {
  const earthRadius = 6371; // Earth's radius in kilometers

  // Convert degrees to radians
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lng2 - lng1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
    Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c; // Distance in kilometers

  return kilometersToMiles(distance);
};
