import { Map, NearMe, Person, Search } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useAuth } from "components/AuthContext";
import { applyTo, pipe } from "ramda";
import { memo, useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { displayName } from "lib/react";

export default applyTo(() => {
  const { isAuthenticated } = useAuth();
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleChange}
      sx={{ position: "fixed", bottom: 0, width: "100%", boxShadow: '0px 0px 5px #666666' }}
    >
      <BottomNavigationAction
        component={Link}
        to="/search"
        label="Search"
        icon={<Search />}
      />
      <BottomNavigationAction
        component={Link}
        to="/explore"
        label="Explore"
        icon={<Map />}
      />
      {/* <BottomNavigationAction component={Link} to='/discover' label="Discover" icon={<LocalMovies />} /> */}
      <BottomNavigationAction
        component={Link}
        to="/locations"
        label="Near Me"
        icon={<NearMe />}
      />
      <BottomNavigationAction component={Link} to={isAuthenticated ? '/profile' : "/login?returnTo=/profile"} label="Profile" icon={<Person />} />
    </BottomNavigation>
  );
}, pipe(displayName("AppFooter"), memo));
