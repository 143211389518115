import { useReadQuery } from "@apollo/client";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  List,
  ListItem,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from "@mui/material";
import { displayName } from "lib/react";
import { applyTo, head, pipe, replace, split } from "ramda";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import SEO from "components/SEO";
import conf from "conf";

export const getTabFromPathname = pipe(
  replace("/profile/", ""),
  split("/"),
  head,
)

export const getIsRootPath = pipe(
  replace("/profile/", ""),
  split("/"),
  (arr) => arr.length === 1,
)

export default applyTo(
  ({ queryRef }) => {
    const { pathname } = useLocation();
    const isMobile = useMediaQuery("(max-width: 1000px)");
    const [tab, setTab] = useState(getTabFromPathname(pathname));
    const [isRootPath, setIsRootPath] = useState(getIsRootPath(pathname));
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const { data } = useReadQuery(queryRef);

    useEffect(() => {
      setTab(getTabFromPathname(pathname));
      setIsRootPath(getIsRootPath(pathname));
    }, [pathname]);

    const handleTabChange = useCallback(
      (_, newTab) => {
        setTab(newTab);
        navigate(`/profile/${newTab}`);
      },
      [setTab, navigate]
    );

    const handleAccordionClick = useCallback(
      (newTab) => () => {
        setTab(newTab);
        navigate(`/profile/${newTab}`);
        setExpanded(false);
      },
      [setTab, navigate, setExpanded]
    );

    const handleAccordionChange = useCallback(() => {
      setExpanded(!expanded);
    }, [setExpanded, expanded]);

    const profileNavigation = useMemo(() => {
      if (!isRootPath) return null;
      return !isMobile ? (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Favorites" value="favorites" />
            <Tab label="Visited" value="visited" />
            <Tab label="Submissions" value="submissions" />
            <Tab label="Lists" value="lists" />
            {conf.env !== "production" && <Tab label="On Set! Premium" value="premium" />}
            <Tab label="Settings" value="settings" />
          </Tabs>
        </Box>
      ) : (
        <Accordion expanded={expanded} onClick={handleAccordionChange}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5" textTransform="capitalize">
              {tab}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem onClick={handleAccordionClick("favorites")}>
                Favorites
              </ListItem>
              <ListItem onClick={handleAccordionClick("visited")}>
                Visited
              </ListItem>
              <ListItem onClick={handleAccordionClick("submissions")}>
                Submissions
              </ListItem>
              <ListItem onClick={handleAccordionClick("lists")}>
                Lists
              </ListItem>
              {conf.env !== "production" && (
                <ListItem onClick={handleAccordionClick("premium")}>
                  Premium
                </ListItem>
              )}
              <ListItem onClick={handleAccordionClick("settings")}>
                Settings
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      );
    }, [
      isMobile,
      handleTabChange,
      tab,
      expanded,
      handleAccordionClick,
      handleAccordionChange,
      isRootPath,
    ]);

    return (
      <Container sx={{ display: 'flex', flexDirection: 'column' }}>
        <SEO title="Profile" />
        {isRootPath && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            paddingBottom={2}
            paddingTop={2}
          >
            <Box flexGrow={1}>
              <Typography variant="h2">
                {data?.whoami?.firstName
                  ? `Welcome back ${data?.whoami?.firstName}!`
                  : "Welcome to On Set!"}
              </Typography>
            </Box>
          </Stack>
        )}
        {profileNavigation}
        <Box display='flex' flexGrow={1} paddingTop={2}>
          <Outlet />
        </Box>
      </Container>
    );
  },
  pipe(
    displayName("Profile"),
    memo
  )
);
