import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { applyTo, mergeRight, pipe } from "ramda";
import { memo } from "react";

import { SelectField, SubmitButton, TextField } from "components/fields";
import { withFormik } from "formik";
import { defaultProps, displayName, propTypes } from "lib/react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  username: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  visibility: Yup.string().oneOf(["PUBLIC", "PRIVATE"]),
});

const INITIAL_VALUES = {
  username: "",
  firstName: "",
  lastName: "",
  visibility: "PRIVATE",
};

export default applyTo(
  ({ handleSubmit }) => {
    return (
      <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField name="username" label="Username" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="firstName" label="First name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="lastName" label="Last name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField fullWidth={true} name="visibility" label="Profile Visibilty" options={[{ label: "Private", value: "PRIVATE" }, { label: "Public", value: "PUBLIC" }]} />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton fullWidth={true} children="Submit" />
        </Grid>
      </Grid>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        return mergeRight(INITIAL_VALUES, initialValues);
      },
      validationSchema,
      validateOnBlur: true,
      // NOTE: since this is async, formik sets `setSubmitting` to false for you
      handleSubmit: async (values, { resetForm, props: { onSubmit } }) => {
        try {
          // pass to parent to do ~something~
          await onSubmit(values);
          // reset the form with `values` as it's new initial values
          resetForm({ values: INITIAL_VALUES });
        } catch (err) {
          console.error(err);
          // reset the form with `values` as it's new initial values
          resetForm({ values });
        }
      },
      displayName: "UserForm",
    }),
    propTypes({
      handleSubmit: PropTypes.func,
    }),
    defaultProps({
      handleSubmit: () => { },
    }),
    displayName("UserForm"),
    memo
  )
);
