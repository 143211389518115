import { Container, List, ListItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import SEO from "components/SEO";
import Youtube from 'components/Youtube';
import { displayName } from "lib/react";

const rows = [
  { title: "Rocky Steps" },
  { title: "Joker Steps" },
  { title: "The Birds House" },
  { title: "Texas Chainsaw house" },
  { title: "Home alone" },
];

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Container sx={{ marginBottom: isMobile ? 10 : 2, marginTop: 2 }}>
      <SEO title="Discover" />
      <Stack gap={10}>
        <Typography variant="h2">Discover</Typography>
        {/* Top Locations */}
        <Stack gap={2}>
          <Typography variant="h3">Top Locations</Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell align="right">Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align='left'>
                      {idx + 1}
                    </TableCell>
                    <TableCell align="left">{row.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        {/* Featured / Top Shows */}
        {/* Featured Set Locations */}
        {/* Featured Tours */}
        <Stack gqp={2}>
          <Typography variant="h3">Featured Tours</Typography>
          <List>
            <ListItem>
              NYC focusVisibleClassName
            </ListItem>
          </List>
        </Stack>
        {/* Featured Youtube */}
        <Stack gap={2}>
          <Typography variant="h3">Featured Community Content</Typography>
          <Youtube url="https://www.youtube.com/embed/-cWBLO6L64E" />
          <Youtube url="https://www.youtube.com/embed/qa3n1TLqij0" />
          <Youtube url="https://www.youtube.com/embed/okQPE14OME0" />
        </Stack>
        {/* Featured User / Creators */}
      </Stack>
    </Container >
  );
}, pipe(displayName("Discover"), memo));
