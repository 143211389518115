import { applyTo, pipe } from "ramda";
import { Suspense, memo } from "react";

import { gql, useBackgroundQuery } from "@apollo/client";
import { displayName } from "lib/react";
import { useParams } from "react-router-dom";

import Content from "./Content";
import Fallback from "./Fallback";

const SHOW_QUERY = gql`
  query ShowQuery($slug: String!) {
    show(slug: $slug) {
      id
      slug
      title
      description
      year
      posterUrl
      trailerUrl
      setLocations(first: 3) {
        edges {
          node {
            id
            slug
            title
            imageUrls
            favorite
          }
        }
      }
    }
  }
`;

export default applyTo(() => {
  const { slug } = useParams();
  const [queryRef] = useBackgroundQuery(SHOW_QUERY, {
    variables: { slug },
  });

  return (
    <Suspense fallback={<Fallback />}>
      <Content queryRef={queryRef} />
    </Suspense>
  );
}, pipe(displayName("Show"), memo));
