import { Alert, Stack, Typography, useMediaQuery } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo, useCallback, useState } from "react";

import { useAuth } from "components/AuthContext";
import SEO from "components/SEO";
import { ForgotPasswordForm } from "components/forms";
import { displayName } from "lib/react";

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { forgotPassword } = useAuth();
  const [status, setStatus] = useState(null);

  const handleSubmit = useCallback(
    async ({ email }) => {
      try {
        await forgotPassword(email);
        setStatus({
          severity: "success",
          children: "Check your email for a password reset link.",
        });
      } catch (err) {
        console.error(err);
        setStatus({
          severity: "error",
          children: err.message,
        });
      }
    },
    [forgotPassword]
  );

  return (
    <Stack flexGrow={1} justifyContent="center" alignItems="center">
      <SEO title="Forgot password" />
      <Stack sx={{ width: 350, marginBottom: isMobile ? 0 : 10 }} gap={2}>
        <Stack gap={2}>
          <Typography variant="h4">Forgot password.</Typography>
          {status && <Alert {...status} />}
          <ForgotPasswordForm onSubmit={handleSubmit} />
        </Stack>
        <Stack gap={0}>
          <Typography variant="body2" fontWeight="400">
            Don't have an account yet?{" "}
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{ color: "#000", textDecoration: "underline" }}
              component="a"
              href="/signup"
            >
              Sign up
            </Typography>
          </Typography>
          <Typography variant="body2" fontWeight="400">
            Forgot your password?{" "}
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{ color: "#000", textDecoration: "underline" }}
              component="a"
              href="/forgot-password"
            >
              Forgot password
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}, pipe(displayName("ForgotPassword"), memo));
