import { gql, useBackgroundQuery } from "@apollo/client";
import { withAuth } from "components/Protected";
import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { Suspense, memo } from "react";
import { useParams } from "react-router-dom";

import Content from "./Content";
import Fallback from "./Fallback";

const USER_BY_USERNAME = gql`
  query PublicProfile($username: String!) {
    user(username: $username) {
      id
      username
      firstName
      lastName
      favorites: lists(type: FAVORITE) {
        edges {
          node {
            id
            total
          }
        }
      }
      visited: lists(type: VISITED) {
        edges {
          node {
            id
            total
          }
        }
      }
      submissions {
        total
      }
      isPremium
    }
  }
`;

export default applyTo(() => {
  const { username } = useParams();
  const [queryRef] = useBackgroundQuery(USER_BY_USERNAME, {
    variables: {
      username,
    },
  });

  return (
    <Suspense fallback={<Fallback />}>
      <Content queryRef={queryRef} />
    </Suspense>
  );
}, pipe(
  withAuth,
  propTypes({
    defaultTab: PropTypes.string,
  }),
  defaultProps({
    defaultTab: "favorites",
  }),
  displayName("Show"), memo));
