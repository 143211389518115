import { applyTo, pipe } from "ramda";
import { memo, useEffect } from "react";

import { displayName } from "lib/react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "components/AuthContext";

export default applyTo(() => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const doLogout = async () => {
      await logout();
      navigate("/login");
    };
    doLogout();
  });

  return null;
}, pipe(displayName("Logout"), memo));
