import { Box, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { applyTo, map, pathOr, pipe } from "ramda";
import { memo, useEffect, useMemo } from "react";

import { useReadQuery } from "@apollo/client";
import { useAppContext } from "components/AppContext";
import SetLocationCard from "components/SetLocationCard";
import { displayName } from "lib/react";
import { path } from "ramda";

import ContentfulRichText from "components/ContentfulRichText";
import SEO from "components/SEO";
import Youtube from "components/Youtube";

export default applyTo(({ queryRef }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { data } = useReadQuery(queryRef);
  const { setTitle, setBackPath } = useAppContext();

  const title = useMemo(() => {
    const title = path(["show", "title"], data);
    const year = path(["show", "year"], data);
    if (year) return `${title} (${year})`;
    return title;
  }, [data]);

  useEffect(() => {
    setTitle(title);
    setBackPath('/search')
  }, [title, setTitle, setBackPath]);

  const trailerUrl = useMemo(() => path(["show", "trailerUrl"], data), [data]);
  const posterUrl = useMemo(() => path(["show", "posterUrl"], data), [data]);
  const description = useMemo(
    () => path(["show", "description"], data),
    [data]
  );
  const setLocations = useMemo(
    () =>
      applyTo(
        data,
        pipe(
          pathOr([], ["show", "setLocations", "edges"]),
          map(path(["node"]))
        )
      ),
    [data]
  );

  return (
    <Container>
      <SEO title={title} />
      <Stack gap={4}>
        <Grid container spacing={4}>
          {isMobile && !description && !trailerUrl ? null : (
            <Grid item xs={12} md={8} sx={{ padding: 0 }}>
              <Stack gap={1}>
                {!isMobile && <Typography variant="h3">{title}</Typography>}
                {description && <ContentfulRichText content={description} />}
                {trailerUrl && <Youtube url={trailerUrl} />}
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            {/* TODO: Where to watch feature (links to netflix or whatever) */}
            {posterUrl && (
              <Box component="img" src={posterUrl} alt="rocky" width="100%" />
            )}
          </Grid>
        </Grid>

        <Stack gap={2} sx={{ marginBottom: 2 }}>
          <Typography variant="h3">Set Locations</Typography>
          <Grid container spacing={2}>
            {setLocations.map((setLocation) => (
              <Grid item xs={12} sm={6} md={4} key={path(["id"], setLocation)}>
                <SetLocationCard
                  id={path(["id"], setLocation)}
                  favorite={path(["favorite"], setLocation)}
                  title={path(["title"], setLocation)}
                  subtitle={title}
                  slug={path(["slug"], setLocation)}
                  imageUrls={pathOr([], ["imageUrls"], setLocation)}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Container>
  );
}, pipe(displayName("Show"), memo));
