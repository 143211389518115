import { Logout, Person } from '@mui/icons-material';
import { ClickAwayListener, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import UserAvatar from "components/UserAvatar";
import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { memo, useState } from "react";
import { useNavigate } from 'react-router-dom';

export default applyTo(({ onClick }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        onClick();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (to) => () => {
        setAnchorEl(null);
        if (to) navigate(to)
    };
    return (
        <>
            <ClickAwayListener onClickAway={handleClose(null)}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <UserAvatar />
                </IconButton>
            </ClickAwayListener>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose('/profile')}>
                    <ListItemIcon>
                        <Person fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={handleClose('/logout')}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}, pipe(
    propTypes({
        onClick: PropTypes.func,
    }),
    defaultProps({
        onClick: () => { },
    }),
    displayName("UserMenu"), memo)
);