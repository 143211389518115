import { Alert, Stack, Typography, useMediaQuery } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "components/AuthContext";
import SEO from "components/SEO";
import { SignUpForm } from "components/forms";
import { displayName } from "lib/react";

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const { signup } = useAuth();

  const handleSubmit = useCallback(
    async ({ email, password }) => {
      try {
        await signup(email, password);
        navigate("/setup");
      } catch (err) {
        console.error(err);
        setStatus({
          severity: "error",
          children: err.message,
        });
      }
    },
    [signup, navigate]
  );

  return (
    <Stack flexGrow={1} justifyContent="center" alignItems="center">
      <SEO title="Signup" />
      <Stack sx={{ width: 350, marginBottom: isMobile ? 0 : 10 }} gap={2}>
        <Stack gap={2}>
          <Typography variant="h4">Sign up</Typography>
          {status && <Alert {...status} />}
          <SignUpForm onSubmit={handleSubmit} />
        </Stack>
        <Stack gap={0}>
          <Typography variant="body2" fontWeight="400">
            Already have an account?{" "}
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{ color: "#000", textDecoration: "underline" }}
              component="a"
              href="/login"
            >
              Log In
            </Typography>
          </Typography>
          <Typography variant="body2" fontWeight="400">
            Forgot your password?{" "}
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{ color: "#000", textDecoration: "underline" }}
              component="a"
              href="/forgot-password"
            >
              Forgot password
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}, pipe(displayName("SignUp"), memo));
