import PropTypes from "prop-types";
import { applyTo, find, pathEq, pipe } from "ramda";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box, Fab, Stack, useMediaQuery } from "@mui/material";
import { MarkerClusterer } from "@react-google-maps/api";
import Map from "components/Map";
import MapMarker from "components/MapMarker";
import { defaultProps, displayName, propTypes } from "lib/react";
import { useSwipeable } from "react-swipeable";

import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  MyLocation,
} from "@mui/icons-material";

import useLocationSearch from "./useLocationSearch";

import initialGeoLocations from "data/geoLocations.json";

import SEO from "components/SEO";
import m1 from "./m1.png";
import m2 from "./m2.png";
import m3 from "./m3.png";
import m4 from "./m4.png";
import m5 from "./m5.png";

import GeoLocationCard from "components/GeoLocationCard/index.js";
import { useCurrentLocation } from "components/LocationContext";
import { getInitialLocation } from "lib/location";
import useDebouncedCallback from "lib/useDebouncedCallback";
import Locations from "views/Locations";

const GOOGLE_MAP_STYLES = [
  {
    height: 70,
    width: 57,
    textLineHeight: 65,
    url: m1,
    textSize: 36,
    fontFamily: "Inter",
  },
  {
    height: 70,
    width: 57,
    textLineHeight: 65,
    url: m2,
    textSize: 36,
    fontFamily: "Inter",
  },
  {
    height: 70,
    width: 57,
    textLineHeight: 65,
    url: m3,
    textSize: 36,
    fontFamily: "Inter",
  },
  {
    height: 70,
    width: 57,
    textLineHeight: 65,
    url: m4,
    textSize: 36,
    fontFamily: "Inter",
  },
  {
    height: 70,
    width: 57,
    textLineHeight: 65,
    url: m5,
    textSize: 36,
    fontFamily: "Inter",
  },
];

const coordinate = getInitialLocation();

export default applyTo(
  () => {
    const isMobile = useMediaQuery("(max-width: 600px)");
    const [bounds, setBounds] = useState(null); // used for geo search
    const [center, setCenter] = useState(coordinate);
    // this is basically a copy of `center` but prevents infinate re-renders
    // since updating the center causes the map to re-render which updates the center and so on.
    // needed for coordinate search for locations list
    const [centerCoordinate, setCenterCoordinate] = useState(center);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { currentLocation, requestCurrentLocation } = useCurrentLocation();

    const swipeHandlers = useSwipeable({
      // note: eventData is not used but is the first arg
      onSwiped: useCallback(() => {
        setSelectedLocation(null);
      }, [setSelectedLocation])
    });

    const [searchLocations, { geoLocations }] =
      useLocationSearch(initialGeoLocations);

    const handleDrawerClick = useCallback(() => {
      setDrawerOpen(!drawerOpen);
    }, [setDrawerOpen, drawerOpen]);

    const handleBoundsChanged = useCallback(
      (bounds) => {
        setBounds(bounds);
      },
      [setBounds]
    );

    const handleCenterChanged = useDebouncedCallback((coordinate) => {
      if (typeof window !== "undefined")
        localStorage.setItem("coordinate", JSON.stringify(coordinate));
      setCenterCoordinate(coordinate);
    }, [setCenterCoordinate]);

    const handleMoveToCurrentLocation = useCallback(() => {
      if (currentLocation) return setCenter(currentLocation);
    }, [setCenter, currentLocation]);

    const handleMarkerClick = useCallback(
      (id) => {
        const geoLocation = find(pathEq(id, ["id"]), geoLocations);
        setSelectedLocation(geoLocation);
      },
      [geoLocations, setSelectedLocation]
    );

    // only re-search once per bounds change
    useEffect(() => {
      if (!bounds) return;
      searchLocations({
        variables: {
          first: 8,
          input: {
            type: "BOUNDING_BOX",
            boundingBox: bounds,
          },
        },
      });
    }, [bounds, searchLocations]);

    /**
     * on mount - attempt to get user's location so we can
     * center the map on the user's location.
     */
    useEffect(() => {
      requestCurrentLocation();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const map = useMemo(() => {
      return (
        <Box position="relative" sx={{ flexGrow: 1 }}>
          {/* Open Close List Toggle */}
          {!isMobile && (
            <Fab
              onClick={handleDrawerClick}
              sx={{ position: "absolute", top: 10, left: 10, zIndex: 100 }}
            >
              {!drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </Fab>
          )}
          {currentLocation && (
            <Fab
              onClick={handleMoveToCurrentLocation}
              sx={{ position: "absolute", bottom: 20, right: 10, zIndex: 100 }}
            >
              <MyLocation />
            </Fab>
          )}
          {selectedLocation && (
            <Box {...swipeHandlers}>
              <GeoLocationCard geoLocation={selectedLocation} />
            </Box>
          )}
          <Map
            onCenterChanged={handleCenterChanged}
            onBoundsChanged={handleBoundsChanged}
            center={center}
          >
            <MarkerClusterer
              imageSizes={[57, 57, 57, 57, 57]}
              // https://googlemaps.github.io/v3-utility-library/interfaces/_google_markerclustererplus.clustericonstyle.html
              styles={GOOGLE_MAP_STYLES}
            >
              {(clusterer) =>
                geoLocations.map((geoLocation) => (
                  <MapMarker
                    geoLocation={geoLocation}
                    clusterer={clusterer}
                    key={geoLocation.id}
                    onClick={handleMarkerClick}
                  />
                ))
              }
            </MarkerClusterer>
          </Map>
        </Box>
      );
    }, [
      geoLocations,
      selectedLocation,
      handleMarkerClick,
      center,
      handleBoundsChanged,
      handleCenterChanged,
      drawerOpen,
      isMobile,
      handleDrawerClick,
      handleMoveToCurrentLocation,
      currentLocation,
      swipeHandlers,
    ]);

    const content = useMemo(() => {
      if (isMobile) return map;
      // desktop
      return (
        <Stack direction="row" position="relative" sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              transition: "width 1s",
              width: "100%",
              maxWidth: 400,
              maxHeight: "calc(100vh - 100px)",
              overflowY: "scroll",
              ...(drawerOpen ? { width: 0 } : { width: -400 }),
              display: 'flex',
            }}
          >
            <Locations coordinate={centerCoordinate} />
          </Box>
          {map}
        </Stack>
      );
    }, [map, isMobile, drawerOpen, centerCoordinate]);

    return (
      <Stack
        flexGrow={1}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <SEO title="Explore" />
        {content}
      </Stack>
    );
  },
  pipe(
    propTypes({
      children: PropTypes.node,
      className: PropTypes.string,
    }),
    defaultProps({
      children: null,
      className: null,
    }),
    displayName("Explore"),
    memo
  )
);
