import { Alert, Stack, Typography, useMediaQuery } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "components/AuthContext";
import SEO from "components/SEO";
import LoginForm from "components/forms/LoginForm";
import { displayName } from "lib/react";

export default applyTo(() => {
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const { login } = useAuth();
  const [status, setStatus] = useState(null);

  const handleSubmit = useCallback(
    async ({ email, password }) => {
      try {
        await login(email, password);
        navigate(searchParams.get('returnTo') || "/explore");
      } catch (err) {
        console.error(err);
        setStatus({
          severity: "error",
          children: err.message,
        });
      }
    },
    [login, navigate, searchParams]
  );

  return (
    <Stack flexGrow={1} justifyContent="center" alignItems="center">
      <SEO title="Login" />
      <Stack sx={{ width: 350, marginBottom: isMobile ? 0 : 10 }} gap={2}>
        <Stack gap={2}>
          <Typography variant="h4">Log in.</Typography>
          {status && <Alert {...status} />}
          <LoginForm onSubmit={handleSubmit} />
        </Stack>
        <Stack gap={0}>
          <Typography variant="body2" fontWeight="400">
            Don't have an account yet?{" "}
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{ color: "#000", textDecoration: "underline" }}
              component="a"
              href="/signup"
            >
              Sign up
            </Typography>
          </Typography>
          <Typography variant="body2" fontWeight="400">
            Forgot your password?{" "}
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{ color: "#000", textDecoration: "underline" }}
              component="a"
              href="/forgot-password"
            >
              Forgot password
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}, pipe(displayName("Login"), memo));
