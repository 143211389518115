import { Container, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { SetLocationCardFallback } from "components/SetLocationCard";
import { displayName } from "lib/react";

export default applyTo(() => {
  return (
    <Container>
      <Stack gap={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Stack gap={1}>
              {/* title */}
              <Skeleton variant="text" width="100%" height={50} />
              {/* address */}
              <Skeleton variant="text" width={200} height={50} />
              {/* image gallery */}
              <Skeleton variant="rectangular" width="100%" height={400} />
              {/* description */}
              <Skeleton variant="text" width="100%" height={50} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack gap={2}>
              {/* Location information */}
              <Skeleton variant="rectangular" width="100%" height={200} />

              {/* Lists */}
              <Skeleton variant="rectangular" width="100%" height={70} />

              {/* Movie Details */}
              <Typography variant="h4">As seen in:</Typography>
              <Skeleton variant="rectangular" width="100%" height={500} />
            </Stack>
          </Grid>
        </Grid>

        <Stack gap={2} sx={{ marginBottom: 2 }}>
          <Typography variant="h3">Related Locations</Typography>
          <Grid container spacing={2}>
            {[1, 2, 3].map((idx) => (
              <Grid item xs={12} sm={6} md={4} key={`set-location-fallback-${idx}`}>
                <SetLocationCardFallback />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Container>
  );
}, pipe(displayName("SetLocation"), memo));
