import {
  Alert,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo, useCallback, useState } from "react";

import { gql, useMutation } from "@apollo/client";
import SEO from "components/SEO";
import SocialLinks from "components/SocialLinks";
import { ContactForm } from "components/forms";
import { displayName } from "lib/react";

const CONTACT = gql`
  mutation Contact($input: ContactInput!) {
    contact(input: $input)
  }
`;

export default applyTo(() => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [contact] = useMutation(CONTACT);
  const [status, setStatus] = useState(null);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await contact({
          variables: {
            input: {
              email: values.email,
              subject: "",
              message: values.message,
            },
          },
        });
        setStatus({
          severity: "success",
          children: "Your message has been sent!",
        });
      } catch (error) {
        console.error(error.message)
        setStatus({
          severity: "error",
          children: "Something went wrong. Please try again.",
        });
      }
    },
    [contact]
  );

  return (
    <Container sx={{ marginBottom: isMobile ? 10 : 2 }}>
      <SEO title="Contact" />
      <Stack gap={10}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={isMobile ? 5 : 10}
          justifyContent="space-between"
        >
          <Stack gap={2}>
            <Stack>
              <Typography variant="h4">Let's get in touch!</Typography>
              <Typography variant="h6">We'd love to hear from you.</Typography>
            </Stack>
            {status && <Alert {...status} />}
            <ContactForm onSubmit={handleSubmit} />
          </Stack>
          <Stack gap={5}>
            <Stack gap={1}>
              <Typography variant="h4">Where to find us</Typography>
              <Typography variant="h6">
                Follow us on any of these channels to stay in the loop.
              </Typography>
              <SocialLinks />
            </Stack>
            <Stack gap={1}>
              <Typography variant="h4">Got some feedback?</Typography>
              <Typography variant="h6">
                We always want to know how we can improve. If you have any
                feedback or feature requests please let us know!
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h4">Business Inquiries</Typography>
              <Typography variant="h6">
                We can help promote your film tourism business! Get in touch via
                our contact form to learn more.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}, pipe(displayName("Contact"), memo));
