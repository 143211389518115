import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { applyTo, mergeRight, pipe } from "ramda";
import { memo } from "react";

import { SubmitButton } from "components/fields";
import TextField from "components/fields/TextField";
import { withFormik } from "formik";
import { defaultProps, displayName, propTypes } from "lib/react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
});

const INITIAL_VALUES = {
  email: "",
  password: "",
};

export default applyTo(
  ({ handleSubmit }) => {
    return (
      <Grid container component="form" spacing={2} onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField name="email" label="Email" />
        </Grid>
        <Grid item xs={12}>
          <TextField name="password" label="Password" type="password" />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton fullWidth={true} children="Submit" />
        </Grid>
      </Grid>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        return mergeRight(INITIAL_VALUES, initialValues);
      },
      validationSchema,
      validateOnBlur: true,
      // NOTE: since this is async, formik sets `setSubmitting` to false for you
      handleSubmit: async (values, { resetForm, props: { onSubmit } }) => {
        try {
          // pass to parent to do ~something~
          await onSubmit(values);
          // reset the form with `values` as it's new initial values
          resetForm({ values: INITIAL_VALUES });
        } catch (err) {
          console.error(err);
          // reset the form with `values` as it's new initial values
          resetForm({ values });
        }
      },
      displayName: "LoginForm",
    }),
    propTypes({
      handleSubmit: PropTypes.func,
    }),
    defaultProps({
      handleSubmit: () => { },
    }),
    displayName("LoginForm"),
    memo
  )
);
