import { gql, useMutation, useReadQuery } from "@apollo/client";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, path, pipe } from "ramda";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useSearchParams } from "react-router-dom";

const SUBSCRIBE = gql`
  mutation Subscribe {
    subscribe {
      redirect
    }
  }
`;

const MANAGE_SUBSCRIPTION = gql`
  mutation ManageSubscription {
    manageSubscription {
      redirect
    }
  }
`;

export default applyTo(({ queryRef }) => {
  const { data } = useReadQuery(queryRef);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExplodingConfetti, setIsExplodingConfetti] = useState(false);
  const [subscribe, { loading: subscribeLoading }] = useMutation(SUBSCRIBE);
  const [manageSubscription, { loading: manageSubscriptionLoading }] = useMutation(MANAGE_SUBSCRIPTION);

  const handleSubscribe = useCallback(
    async () => {
      try {
        const result = await subscribe();
        const stripeCheckoutUrl = path(["data", "subscribe", "redirect"], result);
        window.location.href = stripeCheckoutUrl;
      } catch (err) {
        console.error(err);
      }
    },
    [subscribe]
  );

  const handleManageSubscription = useCallback(
    async () => {
      try {
        const result = await manageSubscription();
        const stripePortalUrl = path(["data", "manageSubscription", "redirect"], result);
        window.location.href = stripePortalUrl;
      } catch (err) {
        console.error(err);
      }
    },
    [manageSubscription]
  );

  const handleConfettiComplete = useCallback(
    () => {
      setIsExplodingConfetti(false);
    },
    [setIsExplodingConfetti]
  );

  useEffect(() => {
    const success = searchParams.get("success");
    if (success) {
      setIsExplodingConfetti(true);
    }
    setSearchParams({});
  }, [searchParams, setSearchParams, setIsExplodingConfetti])

  const subscription = useMemo(() => {
    return applyTo(data, pipe(
      path(["whoami", "subscription"]),
    ))
  }, [data]);

  const hasActiveSubscription = !!subscription;


  return (
    <Stack flexGrow={1} gap={2} sx={{ position: 'relative' }}>
      {isExplodingConfetti && (
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, button: 0, zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ConfettiExplosion duration={5000} onComplete={handleConfettiComplete} />
        </Box>
      )}

      {!hasActiveSubscription ? (
        <Box sx={{ background: "#FFF0B3", height: 250, width: '100%' }} display='flex' justifyContent='center' alignItems='center'>
          <Stack gap={2} maxWidth={400}>
            <Typography variant="h4" textAlign='center'>On Set! Premium</Typography>
            <Typography variant="h6">Support this project and gain access to exclusive features such as scene photos.</Typography>
            <Typography variant="h6" textAlign='center'>Just $4.99 per/month</Typography>
          </Stack>
        </Box >
      ) : (
        <Box sx={{ background: "#FFF0B3", height: 250, width: '100%' }} display='flex' justifyContent='center' alignItems='center'>
          <Stack gap={2} maxWidth={600}>
            <Typography variant="h4" textAlign='center'>On Set! Premium</Typography>
            <Typography variant="h6" textAlign='center'>Thank you for being a premium user and supporting the project.</Typography>
            <Typography variant="h6" textAlign='center'>Stay tuned for exclusive new features in the near future.</Typography>
          </Stack>
        </Box >
      )}

      {!hasActiveSubscription ? (
        <Stack alignItems='center' gap={2}>
          <Typography variant="body1">OnSet! Premium is a subscription service that gives you access to all of OnSet!'s features.</Typography>
          <Button onClick={handleSubscribe} sx={{ width: 200 }}>
            <Stack direction="row" gap={2} alignItems="center">
              <CircularProgress
                size={16}
                style={{ display: subscribeLoading ? "block" : "none" }}
              />
              Subscribe
            </Stack>
          </Button>
        </Stack>
      ) : (
        <Stack alignItems='center' gap={2}>
          <Typography variant="body1">OnSet! Premium is a subscription service that gives you access to all of OnSet!'s features.</Typography>
          <Button onClick={handleManageSubscription} sx={{ width: 300 }}>
            <Stack direction="row" gap={2} alignItems="center">
              <CircularProgress
                size={16}
                style={{ display: manageSubscriptionLoading ? "block" : "none" }}
              />
              Manage Subscription
            </Stack>
          </Button>
        </Stack>
      )}
    </Stack >
  );
}, pipe(propTypes({}), defaultProps({}), displayName("Premium"), memo));
