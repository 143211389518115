import { useReadQuery } from "@apollo/client";
import { Button, CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { useAppContext } from "components/AppContext";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, map, path, pathOr, pipe, prop, } from "ramda";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

export default applyTo(({ queryRef, fetchMore, loading }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { data } = useReadQuery(queryRef);
  const { setTitle, setHeaderContext, setBackPath } = useAppContext();

  useEffect(() => {
    setTitle("");
    setHeaderContext(<Button variant="text" component={Link} to='/profile/lists/create'>Create List</Button>);
    setBackPath(null);
  }, [setTitle, setHeaderContext, setBackPath]);

  const handleRowClick = useCallback((list) => () => {
    navigate(`/profile/lists/${path(['id'], list)}`);
  }, [navigate])

  const pageInfo = useMemo(() => {
    return pathOr({
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      endCursor: null,
    }, ["whoami", "lists", "pageInfo"], data);
  }, [data]);

  const hasNextPage = useMemo(() => {
    return pathOr(false, ["hasNextPage"], pageInfo);
  }, [pageInfo]);

  const handleLoadMore = useCallback(async () => {
    fetchMore({
      variables: {
        first: 5,
        after: path(["endCursor"], pageInfo),
      },
    });
  }, [fetchMore, pageInfo]);

  const lists = useMemo(() => {
    return applyTo(data, pipe(
      pathOr([], ["whoami", "lists", "edges"]),
      map(prop("node")),
    ))
  }, [data]);

  if (lists.length === 0) return (
    <Stack spacing={2} justifyContent='center' alignItems='center' flexGrow={1}>
      <Typography variant='h3'>Nothing here yet.</Typography>
      <Typography variant='body1' textAlign='center'>Custom lists let you organize locations into whatever groups you want.</Typography>
      <Button component={Link} to='/profile/lists/create'>Create List</Button>
    </Stack>
  );

  return (
    <Stack flexGrow={1} gap={2} sx={{ position: 'relative' }}>
      {!isMobile && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" >
          <Typography variant="h4">Lists</Typography>
          <Button component={Link} to='/profile/lists/create'>Create List</Button>
        </Stack>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1"></Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">Title</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">Number of Items</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lists.map((list, idx) => (
            <TableRow onClick={handleRowClick(list)} key={path(['id'], list)} sx={{ textDecoration: 'none' }}>
              <TableCell>
                <Typography variant="body1">{idx + 1}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{path(['title'], list)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{path(['total'], list)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {hasNextPage && (
        <Button
          onClick={handleLoadMore}
          sx={!isMobile && { width: 250, alignSelf: "center" }}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <CircularProgress
              size={16}
              sx={{ display: loading ? "block" : "none" }}
            />
            Load more
          </Stack>
        </Button>
      )}
    </Stack >
  );
}, pipe(propTypes({}), defaultProps({}), displayName("SubmissionsContent"), memo));
