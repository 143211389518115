import { Container, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { SetLocationCardFallback } from "components/SetLocationCard";
import { displayName } from "lib/react";

export default applyTo(() => {
  return (
    <Container>
      <Stack gap={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Stack gap={1}>
              {/* title */}
              <Skeleton width="100%" height={50} />
              {/* description */}
              <Stack>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Stack>
              {/* trailer */}
              <Skeleton width="100%" height={500} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton width="100%" height={500} />
          </Grid>
        </Grid>

        <Stack gap={2} sx={{ marginBottom: 2 }}>
          <Typography variant="h3">Set Locations</Typography>
          <Grid container spacing={2}>
            {[1, 2, 3].map((idx) => (
              <Grid item xs={12} sm={6} md={4} key={`show-fallback-${idx}`}>
                <SetLocationCardFallback />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Container>
  );
}, pipe(displayName("ShowFallback"), memo));
