import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { applyTo, path, pipe } from "ramda";
import { memo } from "react";

import { defaultProps, propTypes } from "lib/react";

const options = () => {
  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children) => (
        <Typography variant="h1" sx={{ marginBottom: 2 }}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <Typography variant="h2" sx={{ marginBottom: 2 }}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (_, children) => (
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (_, children) => (
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {children}
        </Typography>
      ),
      [BLOCKS.HR]: (_) => <Divider />,
      [BLOCKS.UL_LIST]: (_, children) => (
        <List component="ul" sx={{ marginBottom: 2 }}>
          {children}
        </List>
      ),
      [BLOCKS.OL_LIST]: (_, children) => (
        <List component="ol" sx={{ marginBottom: 2 }}>
          {children}
        </List>
      ),
      [BLOCKS.LIST_ITEM]: (_, children) => <ListItem>{children}</ListItem>,
      [BLOCKS.EMBEDDED_ASSET]: node => <Box component="img" maxWidth='100%' src={path(['data', 'target', 'fields', 'file', 'url'], node)} />,
    },
  };
};

export default applyTo(
  ({ className, content }) => {
    if (!content) return null;
    return (
      <div className={className}>
        {documentToReactComponents(content, options(content))}
      </div>
    );
  },
  pipe(
    propTypes({
      children: PropTypes.node,
      className: PropTypes.string,
      content: PropTypes.shape({}),
    }),
    defaultProps({
      children: null,
      className: null,
      content: {},
    }),
    memo
  )
);
