import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { applyTo, mergeRight, pipe } from "ramda";
import { memo } from "react";

import { SubmitButton } from "components/fields";
import TextField from "components/fields/TextField";
import { withFormik } from "formik";
import { defaultProps, displayName, propTypes } from "lib/react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string().required("Required")
    .matches(/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/, "Must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, and one number"),
  confirm: Yup.string().required("Required")
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
});

const INITIAL_VALUES = {
  oldPassword: "",
  newPassword: "",
  confirm: "",
};

export default applyTo(
  ({ handleSubmit }) => {
    return (
      <Grid container component="form" spacing={2} onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField name="oldPassword" label="Current Password" type="password" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="newPassword" label="New Password" type="password" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="confirm" label="Confirm Password" type="password" />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton fullWidth={true} children="Submit" />
        </Grid>
      </Grid>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        return mergeRight(INITIAL_VALUES, initialValues);
      },
      validationSchema,
      validateOnBlur: true,
      // NOTE: since this is async, formik sets `setSubmitting` to false for you
      handleSubmit: async (values, { resetForm, props: { onSubmit } }) => {
        try {
          // pass to parent to do ~something~
          await onSubmit(values);
          // reset the form with `values` as it's new initial values
          resetForm({ values: INITIAL_VALUES });
        } catch (err) {
          console.error(err);
          // reset the form with `values` as it's new initial values
          resetForm({ values });
        }
      },
      displayName: "ResetPasswordForm",
    }),
    propTypes({
      handleSubmit: PropTypes.func,
    }),
    defaultProps({
      handleSubmit: () => { },
    }),
    displayName("ResetPasswordForm"),
    memo
  )
);
