import { gql, useMutation, useReadQuery } from "@apollo/client";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { useAuth } from "components/AuthContext";
import Stat from "components/Stat";
import { ResetPasswordForm, UserForm } from "components/forms";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pathOr, pick, pipe, prop } from "ramda";
import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser {
    deleteUser
  }
`;

export default applyTo(({ queryRef }) => {
  const { resetPassword } = useAuth();
  const { data } = useReadQuery(queryRef);
  const [open, setOpen] = useState(false);
  const [profileStatus, setProfileStatus] = useState(null);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(null);
  const navigate = useNavigate();
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen]);

  const handleDeleteAccountRequest = useCallback(() => {
    setOpen(true)
  }, [setOpen]);


  const handleDeleteAccount = useCallback(
    async () => {
      try {
        await deleteUser();
        navigate("/logout")
      } catch (err) {
        console.error(err);
        // TODO: display error
      }
    },
    [deleteUser, navigate]
  );

  const user = useMemo(() => pathOr({}, ["whoami"], data), [data]);

  const handleProfileSubmit = useCallback(
    async (values) => {
      try {
        await updateUser({
          variables: {
            input: values
          },
        });
        setProfileStatus({
          severity: "success",
          children: "You profile was updated.",
        });
      } catch (err) {
        console.error(err);
        setProfileStatus({
          severity: "error",
          children: err.message,
        });
      }
    },
    [updateUser, setProfileStatus]
  );

  const handleResetPassword = useCallback(
    async (values) => {
      try {
        const oldPassword = prop('oldPassword', values);
        const newPassword = prop('newPassword', values);
        await resetPassword(oldPassword, newPassword);
        setResetPasswordStatus({
          severity: "success",
          children: "Your password was reset.",
        });
      } catch (err) {
        console.error(err);
        setResetPasswordStatus({
          severity: "error",
          children: err.message,
        });
      }
    },
    [resetPassword, setResetPasswordStatus]
  );

  return (
    <Stack flexGrow={1} gap={2} paddingBottom={2}>
      <Typography variant="h4">Achievements</Typography>
      <Stack direction="row" gap={2} alignItems="center">
        <Stat title={pathOr(0, ['favorites', 'edges', 0, 'node', 'total'], user)} subtitle="Favorites" />
        <Stat title={pathOr(0, ['visited', 'edges', 0, 'node', 'total'], user)} subtitle="Visited" />
        <Stat title={pathOr(0, ['submissions', 'total'], user)} subtitle="Submissions" />
      </Stack>
      <Divider />
      <Typography variant="h4">Profile</Typography>
      {profileStatus && <Alert {...profileStatus} />}
      <UserForm onSubmit={handleProfileSubmit} initialValues={pick(['firstName', 'lastName', 'username', 'visibility'], user)} />
      <Divider />
      <Typography variant="h4">Reset Password</Typography>
      {resetPasswordStatus && <Alert {...resetPasswordStatus} />}
      <ResetPasswordForm onSubmit={handleResetPassword} />
      <Divider />
      <Typography variant="h4">Danger zone</Typography>
      <Button variant='outlined' onClick={handleDeleteAccountRequest} sx={{ width: 200, color: "red", borderColor: 'red' }}>Delete Account</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will lose access to your account and all your data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>Cancel</Button>
          <Button variant="text" onClick={handleDeleteAccount} autoFocus>
            Yes, Delete my account.
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}, pipe(propTypes({}), defaultProps({}), displayName("Settings"), memo));
