import { Container, Grid, Stack, Typography } from "@mui/material";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { SetLocationCardFallback } from "components/SetLocationCard";
import { displayName } from "lib/react";

export default applyTo(({ pathname }) => {
  // on explore page only show 1 column regardless of screen size
  const props = pathname !== "/explore" ? { md: 4 } : { md: 12 };
  return (
    <Container>
      <Stack gap={2}>
        <Typography variant="h3">Nearby locations</Typography>
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
            <Grid item xs={12} {...props} key={`locations-${idx}`}>
              <SetLocationCardFallback />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
}, pipe(displayName("Locations"), memo));
