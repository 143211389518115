import { gql, useMutation, useReadQuery } from "@apollo/client";
import { Alert, Container, Stack, Typography } from "@mui/material";
import { UserForm } from "components/forms";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pathOr, pick, pipe } from "ramda";
import { memo, useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export default applyTo(({ queryRef }) => {
  const { data } = useReadQuery(queryRef);
  const [profileStatus, setProfileStatus] = useState(null);
  const navigate = useNavigate();
  const [updateUser] = useMutation(UPDATE_USER);

  const user = useMemo(() => pathOr({}, ["whoami"], data), [data]);

  const handleProfileSubmit = useCallback(
    async (values) => {
      try {
        await updateUser({
          variables: {
            input: values
          },
        });
        setProfileStatus({
          severity: "success",
          children: "You profile was updated.",
        });
        navigate('/profile')
      } catch (err) {
        console.error(err);
        setProfileStatus({
          severity: "error",
          children: err.message,
        });
      }
    },
    [updateUser, setProfileStatus, navigate]
  );

  return (
    <Container>
      <Stack flexGrow={1} gap={2} paddingBottom={2}>
        <Typography variant="h4">Welcome! Let's setup your profile</Typography>
        {profileStatus && <Alert {...profileStatus} />}
        {/* TODO: initial values in the case of a previuosly deleted user */}
        <UserForm onSubmit={handleProfileSubmit} initialValues={pick(['firstName', 'lastName', 'username', 'visibility'], user)} />
        <Typography variant="body2" fontWeight="400" textAlign='center' component={Link} to="/profile">
          Skip
        </Typography>
      </Stack>
    </Container>
  );
}, pipe(propTypes({}), defaultProps({}), displayName("SetupProfileContent"), memo));
