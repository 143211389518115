import { Favorite, FavoriteBorder, MoreVert, Place } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import FlagModal from "components/FlagModal";
import { defaultProps, displayName, propTypes } from "lib/react";
import { useList } from "lib/useList";
import PropTypes from "prop-types";
import { applyTo, head, pipe } from "ramda";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default applyTo(
  ({ id, favorite: initFavorite, title, subtitle, distance, slug, imageUrls, onFavoriteClick, lists }) => {
    const {
      toggle,
      status: favorite,
    } = useList({
      nodeId: id,
      type: "FAVORITE",
      status: initFavorite
    });

    const [moreMenuOpen, setMoreMenuOpen] = useState(false);
    const [flagContentOpen, setFlagContentOpen] = useState(false);
    const moreMenuAnchorRef = useRef(null);

    const handleOpenMoreMenu = useCallback(() => {
      setMoreMenuOpen(true);
    }, [setMoreMenuOpen]);

    const handleCloseMoreMenu = useCallback(() => {
      setMoreMenuOpen(false);
    }, [setMoreMenuOpen]);

    const handleClose = useCallback(() => {
      setFlagContentOpen(false);
    }, [setFlagContentOpen]);

    const handleFlagContentClick = useCallback(async () => {
      setMoreMenuOpen(false);
      // launch flag content modal
      setFlagContentOpen(true);
    }, [setMoreMenuOpen, setFlagContentOpen]);

    const handleFavoriteClick = useCallback(async () => {
      await toggle();
      onFavoriteClick();
    }, [toggle, onFavoriteClick]);

    // initially just show 1 image.
    // maybe this will be a carousel in the future tho.
    const imageUrl = useMemo(() => `${head(imageUrls)}?w=300&h=200&fit=fill&fl=progressive`, [imageUrls]);

    return (
      <Card>
        <FlagModal
          nodeId={id}
          open={flagContentOpen}
          onClose={handleClose} />
        <Box sx={{ position: 'absolute', top: 10, left: 10 }}>
          <IconButton variant='filled' onClick={handleFavoriteClick}>
            {favorite ? <Favorite color='favorite' /> : <FavoriteBorder />}
          </IconButton>
        </Box>
        <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
          <IconButton variant='filled' onClick={handleOpenMoreMenu} ref={moreMenuAnchorRef}>
            <MoreVert />
          </IconButton>
        </Box>
        <CardMedia component="img" src={imageUrl} height={200} />
        <CardContent variant="condensed">
          <Stack direction="row" alignItems="center">
            <Box sx={{ flexGrow: 1 }} width="100%" textOverflow="ellipsis">
              <Typography variant="h5" noWrap>
                {title}
              </Typography>
              <Stack direction="row" alignItems="center" justifyContent='space-between' spacing={1}>
                <Typography variant="body1" noWrap>
                  {subtitle}
                </Typography>
                <Typography variant="body1" noWrap>
                  {distance}
                </Typography>
              </Stack>
            </Box>
            <Popper
              open={moreMenuOpen}
              anchorEl={moreMenuAnchorRef.current}
              placement="top-start"
              transition
              disablePortal>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseMoreMenu}>
                      <MenuList
                        autoFocusItem={moreMenuOpen}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        <MenuItem onClick={handleFlagContentClick}>Flag this content</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            component={Link}
            to={`/location/${slug}`}
            fullWidth
            startIcon={<Place />}
          >
            Location Details
          </Button>
        </CardActions>
      </Card>
    );
  },
  pipe(
    propTypes({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      slug: PropTypes.string,
      imageUrls: PropTypes.arrayOf(PropTypes.string),
      onFavoriteClick: PropTypes.func,
      lists: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })),
    }),
    defaultProps({
      title: "",
      subtitle: "",
      slug: "",
      imageUrls: [],
      onFavoriteClick: () => { },
      lists: [],
    }),
    displayName("SetLocationCard"),
    memo
  )
);
