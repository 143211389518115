import { applyTo, pipe } from "ramda";
import { Suspense, memo } from "react";
import { useParams } from "react-router-dom";

import { displayName } from "lib/react";

import { gql, useBackgroundQuery } from "@apollo/client";

import Content from "./Content";
import Fallback from "./Fallback";

const LOCATION_QUERY = gql`
  query LocationQuery($slug: String!) {
    location(slug: $slug) {
      id
      slug
      title
      description
      imageUrls
      sceneUrl
      geoLocation {
        id
        coordinate {
          lat
          lng
        }
      }
      relatedLocations {
        edges {
          node {
            id
            slug
            title
            imageUrls

            show {
              edges {
                node {
                  id
                  title
                  year
                }
              }
            }

            favorite
          }
        }
      }
      show {
        edges {
          node {
            id
            slug
            posterUrl
            trailerUrl
          }
        }
      }
      seo {
        description
        keywords
      }
      visited
      favorite
    }
  }
`;

export default applyTo(() => {
  const { slug } = useParams();
  const [queryRef] = useBackgroundQuery(LOCATION_QUERY, {
    variables: { slug },
  });

  return (
    <Suspense fallback={<Fallback />}>
      <Content queryRef={queryRef} />
    </Suspense>
  );
}, pipe(displayName("Show"), memo));
