import { useAuth } from "components/AuthContext";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const withAuth = (Component) => {
  const AuthCheck = (props) => {
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const { checkAuth } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
      const check = async () => {
        const isAuthed = await checkAuth();
        if (!isAuthed) {
          navigate(pathname ? `/login?returnTo=${pathname}` : "/login");
        }
        setLoading(false);
      };
      check();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return !loading && <Component {...props} />;
  };
  return AuthCheck;
};
