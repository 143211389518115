import {
    Box,
    Card,
    CardContent,
    Stack,
    Typography
} from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

export default applyTo(
    ({ title, subtitle }) => {
        return (
            <Card>
                <CardContent>
                    <Stack direction="row" alignItems="center">
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" noWrap>
                                {title}
                            </Typography>
                            <Typography variant="body1" noWrap>
                                {subtitle}
                            </Typography>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        );
    },
    pipe(
        propTypes({
            title: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            subtitle: PropTypes.string,
        }),
        defaultProps({
            title: "",
            subtitle: "",
        }),
        displayName("Stat"),
        memo
    )
);
