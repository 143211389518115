import { applyTo, pipe } from "ramda";
import { memo, useEffect } from "react";

import { useAppContext } from "components/AppContext";
import { displayName } from "lib/react";
import { useLocation } from "react-router-dom";

export default applyTo(({ children }) => {
  const location = useLocation();
  const { resetHeader } = useAppContext();

  useEffect(() => {
    resetHeader();
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, resetHeader]);

  return children;
}, pipe(displayName("RouteChange"), memo));
