import { Grid } from "@mui/material";
import { SetLocationCardFallback } from "components/SetLocationCard";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

export default applyTo(() => {
  return (
    <Grid container spacing={2} paddingTop={2}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
        <Grid item xs={12} md={4} key={`favorite-fallback-${idx}`}>
          <SetLocationCardFallback />
        </Grid>
      ))}
    </Grid>
  );
}, pipe(propTypes({}), defaultProps({}), displayName("ViewListFallback"), memo));
