import { gql, useBackgroundQuery } from "@apollo/client";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { Suspense, memo, useCallback, useTransition } from "react";

import Content from "./Content";
import Fallback from "./Fallback";

const SUBMISSIONS = gql`
  query UserSubmissions($first: Int, $after: String) {
    whoami { 
      id
      submissions(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            createdAt
            updatedAt
            title
            address
            description
            status
          }
        }
      }
    }
  }
`;


export default applyTo(() => {
  const [isPending, startTransition] = useTransition();
  const [queryRef, { fetchMore, refetch }] = useBackgroundQuery(SUBMISSIONS, {
    variables: {
      first: 5,
      after: null,
    },
    fetchPolicy: "network-only"
  });

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  return (
    <Suspense fallback={<Fallback />}>
      <Content
        queryRef={queryRef}
        fetchMore={handleFetchMore}
        refetch={handleRefetch}
        loading={isPending} />
    </Suspense>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName("Submissions"), memo));
