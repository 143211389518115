import { createContext, useCallback, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const location = useLocation();
  const hasHistory = location.key !== "default";

  const [title, setTitle] = useState(null);
  const [headerContext, setHeaderContext] = useState(null);
  const [backPath, setBackPath] = useState(-1);

  const reset = useCallback(() => {
    setTitle(null);
    setHeaderContext(null);
    setBackPath(-1);
  }, [setTitle, setHeaderContext, setBackPath]);

  const handleGoBackOr = useCallback((path) => {
    // if we explicity wnat to hide the back button - do that
    if (path === null) {
      setBackPath(null);
      return;
    }
    // if we came form another app route - go back to that one
    if (hasHistory) {
      setBackPath(-1);
    } else {
      // if the user navigated directly to this route - go back to the path provided
      setBackPath(path);
    }
  }, [hasHistory])

  return (
    <AppContext.Provider
      value={{
        title,
        setTitle,
        headerContext,
        setHeaderContext,
        backPath,
        setBackPath: handleGoBackOr,
        resetHeader: reset
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
