import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { defaultProps, displayName, propTypes } from "lib/react";
import { useList } from "lib/useList";
import { applyTo, pathOr, pipe } from "ramda";
import { memo } from "react";

export default applyTo(({ data }) => {
    const {
        toggle: handleToggleFavorite,
        status: favorite,
    } = useList({
        nodeId: pathOr(false, ["location", "id"], data),
        type: "FAVORITE",
        status: pathOr(false, ["location", "favorite"], data)
    });

    return (
        <IconButton variant='button' onClick={handleToggleFavorite} sx={{ width: 50, height: 50 }}>
            {favorite ? <Favorite color='favorite' /> : <FavoriteBorder />}
        </IconButton>
    );
}, pipe(propTypes({}), defaultProps({}), displayName("Favorite"), memo));
