import { gql, useQuery } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { useAuth } from "components/AuthContext";
import CustomListItem from 'components/CustomListItem';
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, map, path, pathOr, pipe } from "ramda";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";

const LISTS = gql`
  query Lists($nodeId: ID!) {
    whoami {
      id
      lists(type: CUSTOM) {
        edges {
          node {
            id
            title
            contains(id: $nodeId)
          }
        }
      }
    }
  }
`;

export default applyTo(({ data }) => {
    const { isAuthenticated } = useAuth();
    const { data: listsData, refetch } = useQuery(LISTS, {
        variables: {
            nodeId: path(["location", "id"], data),
        },
        skip: !isAuthenticated,
    });
    const [listMenuOpen, setListMenuOpen] = useState(false);
    const listMenuAnchorRef = useRef(null);

    const handleOpenListMenu = useCallback(() => {
        setListMenuOpen(true);
    }, [setListMenuOpen]);

    const handleCloseListMenu = useCallback(() => {
        setListMenuOpen(false);
        refetch();
    }, [setListMenuOpen, refetch]);

    const lists = useMemo(() => {
        return applyTo(listsData, pipe(
            pathOr([], ["whoami", "lists", "edges"]),
            map(path(["node"]))
        ))
    }, [listsData]);

    const nodeId = useMemo(() => path(["location", "id"], data), [data]);

    return (
        <>
            <IconButton variant='button' onClick={handleOpenListMenu} ref={listMenuAnchorRef} sx={{ width: 50, height: 50 }}>
                <Add />
            </IconButton>
            <Popper
                open={listMenuOpen}
                anchorEl={listMenuAnchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: "10" }}>
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseListMenu}>
                                <MenuList
                                    autoFocusItem={listMenuOpen}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    {lists.map(({ id: listId, title: listTitle, contains }) => (
                                        <CustomListItem
                                            key={listId}
                                            listId={listId}
                                            nodeId={nodeId}
                                            title={listTitle}
                                            status={contains}
                                            onClick={handleCloseListMenu} />
                                    ))}
                                    <MenuItem component={Link} to='/profile/lists/create' onClick={handleCloseListMenu}>Create a new List</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}, pipe(propTypes({}), defaultProps({}), displayName("Lists"), memo));
