const markdown = `
#### On Set! Terms and Conditions

<br />
<hr />
<br />

> Welcome to On Set! These terms and conditions govern your use of the On Set! website and mobile application (the "App") provided by On Set! Inc. ("we," "us," or "our"). By accessing or using the App, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use the App.

<br />
<br />

###### 1. User Accounts and Registration
- 1.1. To access certain features of the App, you may be required to create an account. You must provide accurate, complete, and up-to-date information during the registration process.
- 1.2. You are solely responsible for maintaining the confidentiality of your account login credentials. Any activity that occurs under your account is your responsibility.
- 1.3. You agree not to use another user's account or share your account with others. If you suspect unauthorized access to your account, you must inform us immediately.


###### 2. Content Submission and Use
- 2.1. On Set! allows users to view and submit set locations and scene photos. By submitting content to the App, you grant us a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, display, and modify the content for the purposes of data accuracy, operating, and promoting the App.
- 2.2. You may not submit any content that is unlawful, offensive, abusive, defamatory, or violates the rights of others.
- 2.3. We reserve the right to remove any content that violates these terms or is deemed inappropriate.


###### 3. Premium Subscription
- 3.1. On Set! offers users On Set! Premium which provides additional features and benefits ("Premium Subscription").
- 3.2. The Premium Subscription is available through our App and is subject to the terms and conditions set by the app store from which you download the App.
- 3.3. Payment for the Premium Subscription is non-refundable unless required by applicable law.


###### 4. Privacy Policy
- 4.1. Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal information.
- 4.2. By using the App, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.


###### 5. Intellectual Property
- 5.1. The App and its content, including but not limited to logos, trademarks, text, images, and software, are the property of On Set! LLC. and protected by copyright and other intellectual property laws.
- 5.2. You may not use, reproduce, modify, distribute, or exploit any part of the App or its content without our prior written consent.


###### 6. Disclaimer of Warranties
- 6.1. The App and its content are provided on an "as is" and "as available" basis without warranties of any kind, either express or implied.
- 6.2. We do not warrant that the App will be error-free, uninterrupted, or free from viruses or other harmful components.
- 6.3. We do not endorse the accuracy, reliability, or legality of content submitted by users.


###### 7. Limitation of Liability
- 7.1. To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to the use of the App or its content.
- 7.2. Our total liability for any claim arising from the App shall not exceed the amount you paid for the Premium Subscription, if applicable.


###### 8. Modification of Terms
- 8.1. We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting the revised terms on the App.
- 8.2. Your continued use of the App after the posting of changes constitutes your acceptance of the revised terms.


###### 9. Termination
- 9.1. We may terminate or suspend your access to the App without prior notice if you violate these terms and conditions or for any other reason at our discretion.
- 9.2. Upon termination, all licenses and rights granted to you will cease, and you must immediately cease using the App.


###### 10. Governing Law
- 10.1. These terms and conditions shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of laws principles.
- 10.2. Any disputes arising out of or relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of [Your Country/State].


Thank you for using On Set! Please enjoy exploring set locations, sharing scene photos, and being part of our community. If you have any questions or concerns, please contact us at support@onsetapp.com.

<br />

Effective date: 7/24/2023

<br />
<br />
`;

export default markdown;
