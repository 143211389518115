import { Button, CircularProgress, FormControl, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { applyTo, isEmpty, pipe, propOr } from "ramda";
import { memo } from "react";

import { defaultProps, displayName, propTypes } from "lib/react";

export default applyTo(
  ({ className, children, ...rest }) => {
    const { isSubmitting, isValid, touched } = useFormikContext();

    return (
      <FormControl
        fullWidth={propOr(true, "fullWidth", rest)}
        className={className}
      >
        <Button
          type="submit"
          fullWidth={true}
          disabled={isSubmitting || !isValid || isEmpty(touched)}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <CircularProgress
              size={16}
              style={{ display: isSubmitting ? "block" : "none" }}
            />
            {children}
          </Stack>
        </Button>
      </FormControl>
    );
  },
  pipe(
    propTypes({
      className: PropTypes.string,
      children: PropTypes.string,
    }),
    defaultProps({
      className: null,
      children: "Submit",
    }),
    displayName("SubmitButton"),
    memo
  )
);
