import { useReadQuery } from "@apollo/client";
import {
  Box,
  Container,
  Stack,
  Typography
} from "@mui/material";
import { displayName } from "lib/react";
import { applyTo, path, pathOr, pipe, } from "ramda";
import { memo, useMemo } from "react";

import Stat from "components/Stat";
import conf from "conf";

export default applyTo(
  ({ queryRef }) => {
    const { data } = useReadQuery(queryRef);

    const user = useMemo(() => path(["user"], data), [data]);
    const isPremium = useMemo(() => pathOr(false, ['isPremium'], user), [user]);

    if (!user) {
      return (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            paddingBottom={2}
            paddingTop={2}
          >
            <Box flexGrow={1}>
              <Typography variant="h2">
                User not found.
              </Typography>
            </Box>
          </Stack>
        </Container>
      )
    }

    return (
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          paddingBottom={2}
          paddingTop={2}
        >
          <Box flexGrow={1}>
            <Stack gap={2}>
              <Typography variant="h2">
                {`${data?.user?.firstName} ${data?.user?.lastName}`}
              </Typography>
              {conf.env !== "production" && isPremium && (
                <Box sx={{ background: "#FFF0B3", height: 250, width: '100%' }} display='flex' justifyContent='center' alignItems='center'>
                  <Stack gap={2} maxWidth={600}>
                    <Typography variant="h4" textAlign='center'>On Set! Premium Member</Typography>
                  </Stack>
                </Box >
              )}
              <Typography variant="h4">Achievements</Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Stat title={pathOr(0, ['favorites', 'edges', 0, 'node', 'total'], user)} subtitle="Favorites" />
                <Stat title={pathOr(0, ['visited', 'edges', 0, 'node', 'total'], user)} subtitle="Visited" />
                <Stat title={pathOr(0, ['submissions', 'total'], user)} subtitle="Submissions" />
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    );
  },
  pipe(
    displayName("PublicProfile"),
    memo
  )
);
