import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
  fromPromise,
} from "@apollo/client";
import { setContext, } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import config from "conf";
import { applyTo, pathOr, pipe } from "ramda";
import { RouterProvider } from "react-router-dom";

import { displayName } from "lib/react";
import { memo } from "react";

import { Auth } from "aws-amplify";
import { AuthProvider } from "components/AuthContext";
import router from "lib/router";
import theme from "lib/theme";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

const httpLink = createHttpLink({
  uri: `${config.API_HOST}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const refreshSession = async (cognitoUser, currentSession) => {
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) reject(err);
      const newAccessToken = pathOr(null, ['accessToken', 'jwtToken'], session);
      console.log('got a brand new newAccessToken')
      window.localStorage.setItem("token", newAccessToken);
      resolve(newAccessToken);
    })
  })
};

const getNewToken = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession()
      console.log('currentSession - just before getting new token - is it before the error? (it is after for some reason)')
      const accessToken = await refreshSession(cognitoUser, currentSession);
      console.log('accessToken - got it - passing it back to onError')
      return resolve(accessToken);
    } catch (error) {
      console.log('error getting new token')
      return reject(error);
    }
  })
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
    for (let err of graphQLErrors) {
      console.log('code', err.extensions.code)

      switch (err.extensions.code) {

        case "UNAUTHENTICATED":
          try {
            return fromPromise(
              getNewToken()
                .catch(error => {
                  console.error('is this the error?', error)
                  window.location.href = "/logout";
                  return;
                })
            )
              .filter((value) => {
                console.log('in filter', value)
                return Boolean(value)
              })
              .flatMap((accessToken) => {
                console.log('in flatMap')
                const oldHeaders = operation.getContext().headers;
                // modify the operation context with the new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
                return forward(operation); // retry the request
              })
          } catch (e) {
            console.log('Unable to refresh Token', e);
            window.location.href = "/logout";
            return;
          }
        default:
          console.log('default error case')
          toast.error(err.message);
          return;
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    toast.error("Network error");
  }
  console.log('still running onError (after network error)...')

});

const client = new ApolloClient({
  link: from([
    errorLink,
    authLink,
    httpLink,
  ]),
  connectToDevTools: config.env !== "production",
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          search: relayStylePagination(["input"]),
        },
      },
      List: {
        fields: {
          items: relayStylePagination(),
        }
      },
      User: {
        fields: {
          submissions: relayStylePagination(),
          lists: relayStylePagination(["type"]),
        }
      }
    },
  }),
});

export default applyTo(() => {
  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer icon={false} />
          <RouterProvider router={router} />
        </ThemeProvider>
      </ApolloProvider>
    </AuthProvider>
  );
}, pipe(displayName("App"), memo));
