import { Grid, Stack, Typography } from "@mui/material";
import { applyTo, map, path, pathOr, pipe } from "ramda";
import { memo, useMemo } from "react";

import SetLocationCard from "components/SetLocationCard";
import { displayName } from "lib/react";

import { useReadQuery } from "@apollo/client";

export default applyTo(({ queryRef }) => {
  const { data } = useReadQuery(queryRef);

  const featuredLocations = useMemo(() => {
    return applyTo(
      data,
      pipe(
        pathOr([], ["featuredLocationGroups", "edges"]),
        path([0, "node"]), // just get the first one for now "All time greats"
        pathOr([], ["setLocations", "edges"]),
        map(path(["node"]))
      )
    );
  }, [data]);

  return (
    <Stack gap={2}>
      <Typography
        variant="h4"
        sx={{ fontFamily: "PalmCanyonDrive" }}
        textAlign="center"
      >
        Check this out!
      </Typography>
      <Typography variant="h2" sx={{ fontWeight: 900 }} textAlign="center">
        Featured Locations
      </Typography>
      <Grid container spacing={2}>
        {featuredLocations.map((setLocation) => {
          const title = path(
            ["show", "edges", 0, "node", "title"],
            setLocation
          );
          const year = path(["show", "edges", 0, "node", "year"], setLocation);
          const subtitle = year ? `${title} (${year})` : title;

          return (
            <Grid item xs={12} md={4} key={path(["id"], setLocation)}>
              <SetLocationCard
                id={path(["id"], setLocation)}
                favorite={path(["favorite"], setLocation)}
                title={path(["title"], setLocation)}
                subtitle={subtitle}
                slug={path(["slug"], setLocation)}
                imageUrls={pathOr([], ["imageUrls"], setLocation)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
}, pipe(displayName("FeaturedLocations"), memo));
