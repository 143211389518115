import { gql, useQuery } from "@apollo/client";
import { Favorite, FavoriteBorder, MoreVert } from "@mui/icons-material";
import { ClickAwayListener, Grow, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, useMediaQuery } from "@mui/material";
import { useAuth } from "components/AuthContext";
import CustomListItem from 'components/CustomListItem';
import FlagModal from 'components/FlagModal';
import { defaultProps, displayName, propTypes } from "lib/react";
import { useList } from "lib/useList";
import { applyTo, map, path, pathOr, pipe } from "ramda";
import { memo, useCallback, useMemo, useRef, useState } from "react";

const LISTS = gql`
  query Lists($nodeId: ID!) {
    whoami {
      id
      lists(type: CUSTOM) {
        edges {
          node {
            id
            title
            contains(id: $nodeId)
          }
        }
      }
    }
  }
`;

export default applyTo(({ data }) => {
    const { isAuthenticated } = useAuth();
    const { data: listsData, refetch } = useQuery(LISTS, {
        variables: {
            nodeId: path(["location", "id"], data),
        },
        skip: !isAuthenticated,
    });
    const isMobile = useMediaQuery("(max-width: 600px)");
    const [moreMenuOpen, setMoreMenuOpen] = useState(false);
    const moreMenuAnchorRef = useRef(null);
    const [flagContentOpen, setFlagContentOpen] = useState(false);

    const {
        toggle: handleToggleFavorite,
        status: favorite,
    } = useList({
        nodeId: pathOr(false, ["location", "id"], data),
        type: "FAVORITE",
        status: pathOr(false, ["location", "favorite"], data)
    });

    const handleOpenMoreMenu = useCallback(() => {
        setMoreMenuOpen(true);
    }, [setMoreMenuOpen]);

    const handleCloseMoreMenu = useCallback(() => {
        setMoreMenuOpen(false);
        refetch()
    }, [setMoreMenuOpen, refetch]);

    const handleFlagContentClick = useCallback(async () => {
        setMoreMenuOpen(false);
        // launch flag content modal
        setFlagContentOpen(true);
    }, [setMoreMenuOpen]);

    const handleClose = useCallback(() => {
        setFlagContentOpen(false);
    }, [setFlagContentOpen]);

    const lists = useMemo(() => {
        return applyTo(listsData, pipe(
            pathOr([], ["whoami", "lists", "edges"]),
            map(path(["node"]))
        ))
    }, [listsData]);

    const nodeId = useMemo(() => path(["location", "id"], data), [data]);

    return (
        <>
            <FlagModal
                nodeId={path(["location", "id"], data)}
                open={flagContentOpen}
                onClose={handleClose} />
            <IconButton variant="button" onClick={handleOpenMoreMenu} ref={moreMenuAnchorRef} sx={{ width: 50, height: 50 }}>
                <MoreVert />
            </IconButton>
            <Popper
                open={moreMenuOpen}
                anchorEl={moreMenuAnchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: "10" }}>
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseMoreMenu}>
                                <MenuList
                                    autoFocusItem={moreMenuOpen}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    {isMobile && (
                                        <MenuItem onClick={handleToggleFavorite}>
                                            <ListItemText>Favorite</ListItemText>
                                            <ListItemIcon sx={{ justifyContent: 'flex-end', '&>button': { padding: 0 } }}>
                                                {favorite ? <Favorite color='favorite' /> : <FavoriteBorder />}
                                            </ListItemIcon>
                                        </MenuItem>
                                    )}
                                    {/* TODO: add lists here */}
                                    {lists.map(({ id: listId, title: listTitle, contains }) => (
                                        <CustomListItem
                                            key={listId}
                                            listId={listId}
                                            nodeId={nodeId}
                                            title={listTitle}
                                            status={contains}
                                            onClick={handleCloseMoreMenu} />
                                    ))}
                                    <MenuItem onClick={handleFlagContentClick}>Flag this content</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}, pipe(propTypes({}), defaultProps({}), displayName("MoreMenu"), memo));
