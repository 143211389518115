import { gql, useBackgroundQuery } from "@apollo/client";
import { withAuth } from "components/Protected";
import { defaultProps, displayName, propTypes } from "lib/react";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { Suspense, memo } from "react";

import Content from "./Content";
import Fallback from "./Fallback";

const WHOAMI = gql`
  query WhoAmI {
    whoami {
      id
      email
      username
      firstName
      lastName
    }
  }
`;

export default applyTo(({ defaultTab }) => {
  const [queryRef] = useBackgroundQuery(WHOAMI);

  return (
    <Suspense fallback={<Fallback />}>
      <Content queryRef={queryRef} defaultTab={defaultTab} />
    </Suspense>
  );
}, pipe(
  withAuth,
  propTypes({
    defaultTab: PropTypes.string,
  }),
  defaultProps({
    defaultTab: "favorites",
  }),
  displayName("Profile"), memo));
