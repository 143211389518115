import { Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Stack, Typography } from "@mui/material";
import { applyTo, map, path, pathOr, pipe, prop } from "ramda";
import { memo, useMemo } from "react";

import { useReadQuery } from "@apollo/client";
import { ArrowForward } from "@mui/icons-material";
import SEO from "components/SEO";
import { displayName } from "lib/react";
import { Link } from "react-router-dom";


export default applyTo(({ queryRef }) => {
  const { data } = useReadQuery(queryRef);

  const blogs = useMemo(() => {
    if (!data) return [];
    return applyTo(
      data,
      pipe(
        pathOr([], ["blogs", "edges"]),
        map(prop("node")),
      )
    );
  }, [data]);

  return (
    <Container>
      <SEO title="Blog" />
      <Stack gap={2} sx={{ marginBottom: 2 }}>
        <Typography variant="h2">Blog</Typography>
        <Grid container spacing={2}>
          {blogs.map((blog) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={path(["id"], blog)}>
                <Card>
                  <CardMedia component="img" src={path(['imageUrl'], blog)} height={200} />
                  <CardContent variant="condensed">
                    <Typography variant='h5'>{path(['title'], blog)}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={Link}
                      to={`/blog/${path(['slug'], blog)}`}
                      fullWidth
                      endIcon={<ArrowForward />}
                    >
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container >
  );
}, pipe(displayName("BlogsContent"), memo));
