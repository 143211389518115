import { gql, useBackgroundQuery } from "@apollo/client";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { Suspense, memo, useCallback, useTransition } from "react";

import Content from "./Content";
import Fallback from "./Fallback";

const LISTS = gql`
  query UserLists($first: Int, $after: String) {
    whoami { 
      id
      lists(first: $first, after: $after, type: CUSTOM) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            total 
            items {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default applyTo(() => {
  const [isPending, startTransition] = useTransition();
  const [queryRef, { fetchMore, refetch }] = useBackgroundQuery(LISTS, {
    variables: {
      first: 5,
      after: null,
    },
    fetchPolicy: "network-only"
  });

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  return (
    <Suspense fallback={<Fallback />}>
      <Content
        queryRef={queryRef}
        fetchMore={handleFetchMore}
        refetch={handleRefetch}
        loading={isPending} />
    </Suspense>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName("Lists"), memo));
