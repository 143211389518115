import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { applyTo, pipe } from "ramda";
import { memo } from "react";
import ReactPlayer from "react-player";

import { defaultProps, displayName, propTypes } from "lib/react";

export default applyTo(
  ({ url }) => {
    return (
      <Box sx={{ position: "relative", paddingTop: "56.25%", flexGrow: 1 }}>
        <ReactPlayer
          url={url}
          controls
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          width="100%"
          height="100%"
        />
      </Box>
    );
  },
  pipe(
    propTypes({
      url: PropTypes.string,
    }),
    defaultProps({
      url: null,
    }),
    displayName("Youtube"),
    memo
  )
);
