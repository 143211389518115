const markdown = `
#### On Set! Privacy Policy

<br />
<hr />
<br />

Effective date: 7/24/23

<br />

Welcome to On Set! This Privacy Policy outlines how On Set! Inc. ("we," "us," "our") collects, uses, and protects your personal information when you access or use the On Set! website and mobile application (the "App"). By using the App, you consent to the practices described in this Privacy Policy.

<br />

1. Information We Collect
- 1.1. Personal Information: When you create an account on the App, we may collect personal information such as your name, email address, and location.
- 1.2. Content: If you submit set locations, scene photos, or any other content to the App, we may collect and store that information.
- 1.3. Usage Data: We automatically collect certain information when you access or use the App, including your IP address, device information, browser type, and operating system.
- 1.4. Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience on the App and gather information about how you use it.

2. Use of Information
- 2.1. We use the information collected for the following purposes:
    - a. To provide and improve the App's features and functionality.
    - b. To personalize your experience and deliver content relevant to your interests.
    - c. To communicate with you, respond to your inquiries, and provide customer support.
    - d. To analyze App usage and trends, and conduct research and analysis to enhance user experience.
    - e. To protect the security and integrity of the App and our users.


3. Sharing of Information
    - 3.1. We may share your information in the following circumstances:
        - a. With other users when you submit content to the App.
        - b. With third-party service providers who assist us in operating the App and providing services to you.
        - c. When required by law or in response to a valid legal request.


4. Your Choices
- 4.1. Account Settings: You can review and update your account information by accessing your account settings on the App.
- 4.2. Cookies: You can control cookies through your browser settings and other tools. However, disabling cookies may limit your ability to use certain features of the App.
- 4.3. Communications: You can opt-out of receiving promotional emails by following the instructions provided in the email.


5. Data Security
- 5.1. We employ reasonable security measures to protect your personal information from unauthorized access, use, or disclosure.
- 5.2. However, no data transmission over the internet or electronic storage method is entirely secure, and we cannot guarantee the absolute security of your information.


6. Children's Privacy
- 6.1. The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us immediately.


7. Changes to the Privacy Policy
- 7.1. We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.
- 7.2. We will notify you of any material changes to this Privacy Policy by posting a notice on the App or through other communication channels.


8. Contact Us
- 8.1. If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at privacy@onsetapp.com.


Thank you for using On Set! We value your privacy and are committed to protecting your personal information.

<br />

Effective date: 7/24/2023

<br />
<br />
`;

export default markdown;
