import { createTheme, responsiveFontSizes } from "@mui/material";

import AcmeRegular from "fonts/Acme/Acme-Regular.ttf";
import InterBlack from "fonts/Inter/Inter-Black.ttf";
import InterLight from "fonts/Inter/Inter-Light.ttf";
import InterRegular from "fonts/Inter/Inter-Regular.ttf";
import PalmCanyonDriveLight from "fonts/PalmCanyonDrive/PalmCanyonDrive-Light.otf";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#ffffff",
    },
    background: {
      default: "#FDF9ED",
    },
    favorite: {
      main: "#fe251b",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: ["Inter"].join(","),

    h1: {
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: 72,
      lineHeight: 1.1,
      letterSpacing: -3,
    },

    h2: {
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: 56,
      lineHeight: 1.1,
      letterSpacing: -2.4,
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 1.1,
      letterSpacing: -1.5,
    },
    h4: {
      fontFamily: "Inter",
      fontWeight: 600,
      letterSpacing: -0.55,
    },
    h5: {
      fontFamily: "Inter",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "Inter",
      fontWeight: 400,
      letterSpacing: -0.4,
    },
    body1: {
      fontFamily: "Inter",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "Inter",
      fontWeight: 600,
    },

    legal: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 14,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
              @font-face {
                font-family: 'Inter';
                font-weight: 100;
                src: url("${InterLight}") format('truetype');
              }


              @font-face {
                font-family: 'Inter';
                font-weight: 400;
                src: url("${InterRegular}") format('truetype');
              }

              @font-face {
                font-family: 'Inter';
                font-weight: 900;
                src: url("${InterBlack}") format('truetype');
              }

              @font-face {
                font-family: 'PalmCanyonDrive';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: url("${PalmCanyonDriveLight}") format('opentype');
              }

              @font-face {
                font-family: 'Acme';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: url("${AcmeRegular}") format('truetype');
              }
            `,
    },

    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            background: "#FFF0B3",
            color: "#000000",
            borderRadius: "8px",
            border: "none",
            height: 60,
            padding: "0 30px",
            textTransform: "none",
            fontFamily: "Arial",
            fontWeight: 700,
            fontSize: "20px",
            letterSpacing: "-0.5px",
            lineHeight: "28px",
            "&:hover": {
              background: "#FFF9C1",
            },
            "&:disabled": {
              background: "#FFF0B3",
              opacity: 0.8,
              cursor: "not-allowed",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            border: 1,
            borderStyle: "solid",
            borderColor: "#000000",
            color: "#000000",
            borderRadius: "8px",
            height: 60,
            padding: "0 30px",
            textTransform: "none",
          },
        },
      ],
    },

    MuiToolbar: {
      defaultProps: {
        sx: {
          width: "100%",
          height: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
    },

    MuiAppBar: {
      defaultProps: {
        sx: {
          zIndex: 1000,
          boxShadow: "none",
          background: "#FDF9ED",
          color: "#000000",
        },
      },
      variants: [
        {
          props: { variant: "header" },
          style: {
            boxShadow: "none",
            background: "#FDF9ED",
            color: "#000000",
            height: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      ],
    },

    MuiCard: {
      styleOverrides: {
        root: {
          position: "relative",
        },
      },
    },

    MuiIconButton: {
      variants: [
        {
          props: { variant: "button" },
          style: {
            "&:hover": {
              background: "#D3D3D3",
            },
          },
        },
        {
          props: { variant: "filled" },
          style: {
            background: "#ffffff",
            "&:hover": {
              background: "#D3D3D3",
            },
          },
        },
      ],
    },

    MuiCardContent: {
      variants: [
        {
          props: { variant: "condensed" },
          style: {
            paddingTop: 5,
            paddingBottom: 0,
          },
        },
      ],
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },

    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          background: "#FDF9ED",
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "#FDF9ED",
          border: "1px solid black",
          borderRadius: "8px",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF0B3",
        },
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: "none",
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
