// (signedUrl, File) => string (s3 url)
export const uploadFile = async (signedUrl, file) => {
    return fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: new Headers({
            'Content-Type': file.type
        })
    })
}

export const mimeTypeToFileExt = (mimeType) => {
    switch (mimeType) {
        case 'image/jpeg':
            return 'jpg'
        case 'image/png':
            return 'png'
        case 'image/gif':
            return 'gif'
        default:
            throw new Error(`File Extension Not Recognized ${mimeType}`)
    }
}