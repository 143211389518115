import { gql, useBackgroundQuery } from "@apollo/client";
import { defaultProps, displayName, propTypes } from "lib/react";
import { applyTo, pipe } from "ramda";
import { Suspense, memo, useCallback, useTransition } from "react";

import Content from "./Content";
import Fallback from "./Fallback";

const USER_SETTINGS = gql`
  query UserSettings {
    whoami {
      id
      firstName
      lastName
      username
      visibility
      favorites: lists(type: FAVORITE) {
        edges {
          node {
            id
            total
          }
        }
      }
      visited: lists(type: VISITED) {
        edges {
          node {
            id
            total
          }
        }
      }
      submissions {
        total
      }
    }
  }
`;

export default applyTo(() => {
  const [isPending, startTransition] = useTransition();
  const [queryRef, { fetchMore, refetch }] = useBackgroundQuery(USER_SETTINGS);

  const handleRefetch = useCallback((opts) => {
    startTransition(() => {
      refetch(opts);
    });
  }, [refetch, startTransition]);

  const handleFetchMore = useCallback((opts) => {
    startTransition(() => {
      fetchMore(opts);
    });
  }, [fetchMore, startTransition]);

  return (
    <Suspense fallback={<Fallback />}>
      <Content
        queryRef={queryRef}
        fetchMore={handleFetchMore}
        refetch={handleRefetch}
        loading={isPending} />
    </Suspense>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  displayName("Settings"), memo));
