import { Container } from "@mui/material";
import { MuiMarkdown } from "mui-markdown";
import { applyTo, pipe } from "ramda";
import { memo } from "react";

import { displayName } from "lib/react";

import SEO from "components/SEO";
import content from "./content";

export default applyTo(() => {
  return (
    <Container>
      <SEO title="Terms & Conditions" />
      <MuiMarkdown>{content}</MuiMarkdown>
    </Container>
  );
}, pipe(displayName("Terms"), memo));
